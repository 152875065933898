// import axios from 'axios';
import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceCustomer {
    lookupService = new APIServiceLookUp();

    async updateCustomer(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}customer/updateCustomer`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllCustomers(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}customer/getAllCustomer`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getCustomerById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}customer/getCustomerById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deleteCustomerById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}customer/deleteCustomerById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}