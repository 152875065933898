import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { formAtom } from '../../../core/config/atoms';

import { Scrollbars } from 'react-custom-scrollbars';

import ComponentStepA from './components/ComponentStepA';
import ComponentStepB from './components/ComponentStepB';
import ComponentStepC from './components/ComponentStepC';
import ComponentStepD from './components/ComponentStepD';
import ComponentStepE from './components/ComponentStepE';
import ComponentStepF from './components/ComponentStepF';
import ComponentStepG from './components/ComponentStepG';

export default function PanelApplicationAdd(props) {

    const [form, setForm] = useRecoilState(formAtom);

    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(true);

    const [tab, setTab] = useState(1);
    const [step, setStep] = useState(0);

    const navigate = useNavigate();

    const goBack = () => {
        navigate(`/home/applications`);
    }

    const gotoNext = (index, data) => {
        setForm(data);
        setIsEdit(true);
        setStep(data.data.step);
        setTab(index);
    }

    const submit = () => {
        setForm({});
        goBack();
    }

    const loadData = () => {
        if (Object.keys(form).length === 0) {
            setStep(0);
            setIsEdit(false);
        } else {
            setIsEdit(true);
            setStep(form.data.step);
        }
        setLoading(false);
    }

    useEffect(() => {
        props.onTap();
        loadData();
    }, []);

    return (
        <div className="w-full h-screen p-2 flex flex-col bg-gray-50">
            {
                !loading && <Scrollbars>
                    <div className="grid grid-cols-12 grid-gap-2 p-3 overflow-y-scroll">
                        <div className='col-span-12'>
                            <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                                <i className="las la-long-arrow-alt-left text-xl"></i>
                                <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all drivers</h4>
                            </div>
                            <div className="intro-y mt-4">
                                <h3 className="text-2xl font-sans font-bold text-gray-800">Taxi Driver Add Form</h3>
                                <p className="mt-1 text-sm text-gray-600 mb-2">
                                    Please fill all information related to driver.
                                </p>
                                <hr />
                            </div>
                        </div>
                    </div>
                    <div className='ms-2 md:mx-8'>
                        <div className="hidden md:grid grid-cols-7 gap-4">
                            <div className={`col-span-1 flex flex-col items-center justify-center rounded-lg p-4 cursor-pointer hover:shadow-md ${step >= 1 ? 'bg-green-700' : 'bg-green-100'}`} onClick={(e) => { if (step >= 1) setTab(1) }}>
                                <span><i className={`las la-user text-6xl ${step >= 1 ? 'text-white' : 'text-green-700'}`}></i></span>
                                <p className={`text-center text-sm font-medium ${step >= 1 ? 'text-white' : 'text-green-700'}`}>Personal<br />Information</p>
                            </div>
                            <div className={`col-span-1 flex flex-col items-center justify-center rounded-lg p-4 cursor-pointer hover:shadow-md ${step >= 2 ? 'bg-green-700' : step >= 1 ? 'bg-green-100' : 'bg-gray-200'}`} onClick={(e) => { if (step >= 1) setTab(2) }}>
                                <span><i className={`las la-user-check text-6xl ${step >= 2 ? 'text-white' : step >= 1 ? 'text-green-700' : 'text-black'}`}></i></span>
                                <p className={`text-center text-sm font-medium ${step >= 2 ? 'text-white' : step >= 1 ? 'text-green-700' : 'text-black'}`}>Tutor<br />Information</p>
                            </div>
                            <div className={`col-span-1 flex flex-col items-center justify-center rounded-lg p-4 cursor-pointer hover:shadow-md ${step >= 3 ? 'bg-green-700' : step >= 2 ? 'bg-green-100' : 'bg-gray-200'}`} onClick={(e) => { if (step >= 2) setTab(3) }}>
                                <span><i className={`las la-user-shield text-6xl ${step >= 3 ? 'text-white' : step >= 2 ? 'text-green-700' : 'text-black'}`}></i></span>
                                <p className={`text-center text-sm font-medium ${step >= 3 ? 'text-white' : step >= 2 ? 'text-green-700' : 'text-black'}`}>Emergency<br />Information</p>
                            </div>
                            <div className={`col-span-1 flex flex-col items-center justify-center rounded-lg p-4 cursor-pointer hover:shadow-md ${step >= 4 ? 'bg-green-700' : step >= 3 ? 'bg-green-100' : 'bg-gray-200'}`} onClick={(e) => { if (step >= 3) setTab(4) }}>
                                <span><i className={`las la-id-card text-6xl ${step >= 4 ? 'text-white' : step >= 3 ? 'text-green-700' : 'text-black'}`}></i></span>
                                <p className={`text-center text-sm font-medium ${step >= 4 ? 'text-white' : step >= 3 ? 'text-green-700' : 'text-black'}`}>Identity<br />Information</p>
                            </div>
                            <div className={`col-span-1 flex flex-col items-center justify-center rounded-lg p-4 cursor-pointer hover:shadow-md ${step >= 5 ? 'bg-green-700' : step >= 4 ? 'bg-green-100' : 'bg-gray-200'}`} onClick={(e) => { if (step >= 4) setTab(5) }}>
                                <span><i className={`las la-car text-6xl ${step >= 5 ? 'text-white' : step >= 4 ? 'text-green-700' : 'text-black'}`}></i></span>
                                <p className={`text-center text-sm font-medium ${step >= 5 ? 'text-white' : step >= 4 ? 'text-green-700' : 'text-black'}`}>Vehicle<br />Information</p>
                            </div>
                            <div className={`col-span-1 flex flex-col items-center justify-center rounded-lg p-4 cursor-pointer hover:shadow-md ${step >= 6 ? 'bg-green-700' : step >= 5 ? 'bg-green-100' : 'bg-gray-200'}`} onClick={(e) => { if (step >= 5) setTab(6) }}>
                                <span><i className={`las la-money-check-alt text-6xl ${step >= 6 ? 'text-white' : step >= 5 ? 'text-green-700' : 'text-black'}`}></i></span>
                                <p className={`text-center text-sm font-medium ${step >= 6 ? 'text-white' : step >= 5 ? 'text-green-700' : 'text-black'}`}>Subscription<br />Information</p>
                            </div>
                            <div className={`col-span-1 flex flex-col items-center justify-center rounded-lg p-4 cursor-pointer hover:shadow-md ${step >= 7 ? 'bg-green-700' : step >= 6 ? 'bg-green-100' : 'bg-gray-200'}`} onClick={(e) => { if (step >= 6) setTab(7) }}>
                                <span><i className={`las la-certificate text-6xl ${step >= 7 ? 'text-white' : step >= 6 ? 'text-green-700' : 'text-black'}`}></i></span>
                                <p className={`text-center text-sm font-medium ${step >= 7 ? 'text-white' : step >= 6 ? 'text-green-700' : 'text-black'}`}>Verification<br />Information</p>
                            </div>
                        </div>
                        <div className="grid md:hidden grid-cols-7 gap-4">
                            <div className={`col-span-1 flex flex-col items-center justify-center rounded-full p-2 cursor-pointer hover:shadow-md ${step >= 1 ? 'bg-green-700' : 'bg-green-100'}`} onClick={(e) => { if (step >= 1) setTab(1) }}>
                                <p className={`text-center text-sm font-medium ${step >= 1 ? 'text-white' : 'text-green-700'}`}>1</p>
                            </div>
                            <div className={`col-span-1 flex flex-col items-center justify-center rounded-full p-2 cursor-pointer hover:shadow-md ${step >= 2 ? 'bg-green-700' : step >= 1 ? 'bg-green-100' : 'bg-gray-200'}`} onClick={(e) => { if (step >= 1) setTab(2) }}>
                                <p className={`text-center text-sm font-medium ${step >= 2 ? 'text-white' : step >= 1 ? 'text-green-700' : 'text-black'}`}>2</p>
                            </div>
                            <div className={`col-span-1 flex flex-col items-center justify-center rounded-full p-2 cursor-pointer hover:shadow-md ${step >= 3 ? 'bg-green-700' : step >= 2 ? 'bg-green-100' : 'bg-gray-200'}`} onClick={(e) => { if (step >= 2) setTab(3) }}>
                                <p className={`text-center text-sm font-medium ${step >= 3 ? 'text-white' : step >= 2 ? 'text-green-700' : 'text-black'}`}>3</p>
                            </div>
                            <div className={`col-span-1 flex flex-col items-center justify-center rounded-full p-2 cursor-pointer hover:shadow-md ${step >= 4 ? 'bg-green-700' : step >= 3 ? 'bg-green-100' : 'bg-gray-200'}`} onClick={(e) => { if (step >= 3) setTab(4) }}>
                                <p className={`text-center text-sm font-medium ${step >= 4 ? 'text-white' : step >= 3 ? 'text-green-700' : 'text-black'}`}>4</p>
                            </div>
                            <div className={`col-span-1 flex flex-col items-center justify-center rounded-full p-2 cursor-pointer hover:shadow-md ${step >= 5 ? 'bg-green-700' : step >= 4 ? 'bg-green-100' : 'bg-gray-200'}`} onClick={(e) => { if (step >= 4) setTab(5) }}>
                                <p className={`text-center text-sm font-medium ${step >= 5 ? 'text-white' : step >= 4 ? 'text-green-700' : 'text-black'}`}>5</p>
                            </div>
                            <div className={`col-span-1 flex flex-col items-center justify-center rounded-full p-2 cursor-pointer hover:shadow-md ${step >= 6 ? 'bg-green-700' : step >= 5 ? 'bg-green-100' : 'bg-gray-200'}`} onClick={(e) => { if (step >= 5) setTab(6) }}>
                                <p className={`text-center text-sm font-medium ${step >= 6 ? 'text-white' : step >= 5 ? 'text-green-700' : 'text-black'}`}>6</p>
                            </div>
                            <div className={`col-span-1 flex flex-col items-center justify-center rounded-full p-2 cursor-pointer hover:shadow-md ${step >= 7 ? 'bg-green-700' : step >= 6 ? 'bg-green-100' : 'bg-gray-200'}`} onClick={(e) => { if (step >= 6) setTab(7) }}>
                                <p className={`text-center text-sm font-medium ${step >= 7 ? 'text-white' : step >= 6 ? 'text-green-700' : 'text-black'}`}>7</p>
                            </div>
                        </div>
                        <hr className='hidden md:block my-2' />
                        {tab === 1 && <ComponentStepA isEdit={isEdit} gotoNext={gotoNext} data={form} />}
                        {tab === 2 && <ComponentStepB gotoNext={gotoNext} data={form} />}
                        {tab === 3 && <ComponentStepC gotoNext={gotoNext} data={form} />}
                        {tab === 4 && <ComponentStepD gotoNext={gotoNext} data={form} />}
                        {tab === 5 && <ComponentStepE gotoNext={gotoNext} data={form} />}
                        {tab === 6 && <ComponentStepF gotoNext={gotoNext} data={form} />}
                        {tab === 7 && <ComponentStepG gotoNext={submit} data={form} />}
                    </div>
                </Scrollbars>
            }
        </div>
    )
}
