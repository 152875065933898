import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../../core/config/atoms';

import { useAlert } from 'react-alert';
import Lottie from 'lottie-react';

import ServicePlan from '../../../../services/servicePlan';
import ServiceApplication from '../../../../services/serviceApplication';

import animPage from '../../../../assets/anim/anim-pageload.json';
import animBtnLoading from '../../../../assets/anim/anim-btnLoading.json';

export default function ComponentStepF(props) {

    const [token] = useRecoilState(tokenAtom);

    const [members, setMembers] = useState([]);

    const [value, setValue] = useState('nill');

    const [loading, setLoading] = useState(true);
    const [submit, setSubmit] = useState(false);

    const alert = useAlert();
    const planService = new ServicePlan();
    const appService = new ServiceApplication();

    const getRate = () => {
        if (value !== 'nill') {
            return members.find((mem) => mem._id === value).rate;
        }
        return 0;
    }

    const getTunure = () => {
        if (value !== 'nill') {
            return members.find((mem) => mem._id === value).tenuare;
        }
        return 0;
    }

    const handleSubmit = () => {
        setSubmit(true);
        let body = {
            "fid": props.data._id,
            "opration": "updateStepF",
            "data": {
                "planId": value,
                "planTunure": getTunure(),
                "planRate": getRate()
            }
        }
        appService.updateFormA(body, token).then((res) => {
            setSubmit(false);
            if (res.status) {
                alert.show("Application updated!", { type: 'success' });
                props.gotoNext(7, res.data);
            } else {
                alert.show("Server error, please try again!", { type: 'error' });
            }
        });
    }

    const loadData = (ln) => {
        (async function () {
            var body = {
                'lang': ln
            };
            var res = await planService.getPlans(body, token);
            if (res['status']) {
                setMembers(res.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData('en');
        if (props.data.data.membershipInfo) {
            setValue(props.data.data.membershipInfo.planId);
        }
    }, []);

    return (
        <div>
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <div className="mt-4 grid grid-cols-12 gap-6">
                <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                    <div className="shadow">
                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Subscription Information</h3>
                                </div>
                                {
                                    members.length !== 0 && members.map((mem) => {
                                        return <div className="col-span-6">
                                            <div className='flex items-start'>
                                                <input type="radio" autoComplete="off" className="mt-1 p-2 block w-4 h-4 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" checked={value === mem._id} onChange={() => { setValue(mem._id) }} />
                                                <label className="text-xs font-medium text-gray-700 flex flex-col ml-4">
                                                    <span className='text-lg font-medium'>{mem.title} <span className='text-lg text-green-600'>{mem.rate}$</span></span>
                                                    <spna>
                                                        {mem.disc}
                                                    </spna>
                                                </label>
                                            </div>
                                        </div>
                                    })
                                }
                                <div className="col-span-6">
                                    <div className='flex items-start'>
                                        <input type="radio" autoComplete="off" className="mt-1 p-2 block w-4 h-4 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" checked={value === "nill"} onChange={() => { setValue('nill') }} />
                                        <label className="text-xs font-medium text-sky-600 flex flex-col ml-4">
                                            <span className='text-lg font-medium'>Skip for now</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-y hidden sm:block">
                <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                </div>
            </div>
            <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                <button type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700" onClick={() => { handleSubmit() }}>
                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                    {!submit && "SAVE & NEXT"}
                </button>
            </div>
        </div>
    )
}
