import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { AnimatePresence, motion } from "framer-motion";

import { useRecoilState } from 'recoil';
import { userAtom } from '../core/config/atoms';

import PanelDev from '../panels/dev/PanelDev';

import PanelApplicationList from '../panels/driver/applications/PanelApplicationList';
import PanelApplicationAdd from '../panels/driver/applications/PanelApplicationAdd';
import PanelApplicationVerification from '../panels/driver/applications/PanelApplicationVerification';
import PanelDriverVerification from '../panels/driver/applications/PanelDriverVerification';
import PanelVheVerification from '../panels/driver/applications/PanelVheVerification';

import PanelCR1Verification from '../panels/driver/applications/PanelCR1Verification';
import PanelCR2Verification from '../panels/driver/applications/PanelCR2Verification';
import PanelCR3Verification from '../panels/driver/applications/PanelCR3Verification';
import PanelCR4Verification from '../panels/driver/applications/PanelCR4Verification';

import PanelDriverList from '../panels/driver/profile/PanelDriverList';
import PanelVehicleList from '../panels/driver/vehicle/PanelVehicleList';
import PanelSubscriptionList from '../panels/driver/subscription/PanelSubscriptionList';


export default function LayoutDriver() {

    const [userData, setUserData] = useRecoilState(userAtom);

    const [isOpen, setIsOpen] = useState(false);

    const { page } = useParams();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => {
        setIsOpen(false);
    }

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-xl lg:text-2xl font-medium pt-4 ml-6">Taxi Driver Menu</motion.h1>
                        <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="w-64 flex flex-col items-start mt-2 lg:mt-4">
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'applications' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('applications') }}>Applications</li>
                            {userData.role !== 'MANEGER' && <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'driver' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('driver') }}>Taxi Driver</li>}
                            {userData.role !== 'MANEGER' && <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'vehicle' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('vehicle') }}>Vehicle</li>}
                            {userData.role !== 'MANEGER' && <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'subscription' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('subscription') }}>Subscriptions</li>}
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'applications':
                                return (<PanelApplicationList onToggle={toggleMenu} />);
                            case 'applicationAdd':
                                return (<PanelApplicationAdd onTap={closeMenu} />);
                            case 'applicationVerify':
                                return (<PanelApplicationVerification onTap={closeMenu} />);
                            case 'driverVerify':
                                return (<PanelDriverVerification onTap={closeMenu} />);
                            case 'CR1Verify':
                                return (<PanelCR1Verification onTap={closeMenu} />);
                            case 'CR2Verify':
                                return (<PanelCR2Verification onTap={closeMenu} />);
                            case 'CR3Verify':
                                return (<PanelCR3Verification onTap={closeMenu} />);
                            case 'CR4Verify':
                                return (<PanelCR4Verification onTap={closeMenu} />);
                            case 'vehicleVerify':
                                return (<PanelVheVerification onTap={closeMenu} />);
                            case 'driver':
                                return (<PanelDriverList onToggle={toggleMenu} />);
                            case 'vehicle':
                                return (<PanelVehicleList onToggle={toggleMenu} />);
                            case 'subscription':
                                return (<PanelSubscriptionList onToggle={toggleMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
