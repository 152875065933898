import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceVehicle {
    lookupService = new APIServiceLookUp();

    async checkVehicle(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}vehicle/checkVehicle`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllVehicle(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}vehicle/getAllVehicle`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getVehicleById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}vehicle/getVehicleById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateVehicleStatus(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}vehicle/updateStatus`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllActivity(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}vehicle/getAllActivity`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}