import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServicePlan {
    lookupService = new APIServiceLookUp();

    async getPlans(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}plan/getPlans`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updatePlan(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}plan/updatePlan`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}