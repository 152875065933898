// import axios from 'axios';
import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceStaff {
    lookupService = new APIServiceLookUp();

    async loginStaff(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}staff/loginStaff`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deactiveUser(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}staff/deactiveStaff`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updatePassword(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}staff/updatePassword`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateEmail(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}staff/updateEmail`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateUsername(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}staff/updateUsername`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllStaff(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}staff/getAllStaff`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateStaffById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}staff/updateUser`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deleteStaffById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}staff/deleteStaffById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setStaff(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}staff/setStaff`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async sendLink(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}staff/sendLink`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}