import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { useRecoilState } from 'recoil';
import { modeAtom, langAtom, authAtom } from './core/config/atoms';

import PageAuth from './pages/PageAuth';
import PageHome from './pages/PageHome';
import Page404 from './pages/Page404';

export default function App() {

    const [modeStatus] = useRecoilState(modeAtom);
    const [authStatus] = useRecoilState(authAtom);
    const [lang] = useRecoilState(langAtom);

    const { i18n } = useTranslation();

    useEffect(() => {
        const bodyEl = document.querySelector("body");
        modeStatus ? bodyEl.classList.add("dark") : bodyEl.classList.remove("dark");
    }, [modeStatus]);

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, []);

    return (
        <>
            <Router>
                <Routes>
                    <Route exact path="/" element={<PageAuth />} />
                    {authStatus && <Route exact path="/home/:page/*" element={<PageHome />} />}
                    {authStatus && <Route exact path="/home/:page/:id" element={<PageHome />} />}
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Router>
        </>
    )
}