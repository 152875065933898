import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from "react-i18next";

import { useRecoilState } from 'recoil';
import { authAtom, userAtom } from '../../config/atoms';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import icLogo from '../../../assets/image/logo-icon.png';

export default function NavigationBar() {

    const { page } = useParams();

    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [userData, setUserData] = useRecoilState(userAtom);

    const { t } = useTranslation();

    const navigate = useNavigate();

    const logout = () => {
        setAuthStatus('default');
        setUserData({});
        navigate('/');
    }

    const changeMainMenu = (link) => {
        navigate(`/home/${link}`);
    }

    const getHome = () => {
        if (page === 'overview' || page === 'membership') {
            return true;
        }
        return false;
    }

    const getCutomer = () => {
        if (page === 'customer' || page === 'customerInfo') {
            return true;
        }
        return false;
    }

    const getDriver = () => {
        if (page === 'applications' || page === 'applicationAdd' || page === 'applicationVerify' || page === 'driverVerify' || page === 'vehicleVerify' || page === 'driver' || page === 'vehicle' || page === 'subscription') {
            return true;
        }
        if (page === 'CR1Verify' || page === 'CR2Verify' || page === 'CR3Verify' || page === 'CR4Verify') {
            return true;
        }
        return false;
    }

    const getStaff = () => {
        if (page === 'staff' || page === 'staffAdd') {
            return true;
        }
        return false;
    }

    return (
        <>
            {authStatus === 'default' && <Navigate to="/" />}
            <div className="h-screen shadow-md bg-white hidden xs:block">
                <span className="cursor-pointer" onClick={() => { changeMainMenu('overview') }}>
                    <div className="w-16 h-16">
                        <img src={icLogo} className="w-12 m-auto py-2" />
                    </div>
                </span>
                <ul className="flex flex-col items-center">
                    {
                        userData.role !== 'MANEGER' && <Tippy content={"Overview Section"} placement='right'>
                            <li className={`cursor-pointer w-16 ${getHome() ? 'bg-sky-600 hover:bg-sky-700 text-white' : 'hover:bg-sky-100 text-gray-900'}`} onClick={() => { changeMainMenu('overview') }}>
                                <i className="las la-info w-full text-3xl text-center p-3"></i>
                            </li>
                        </Tippy>
                    }
                    <Tippy content={"Driver Section"} placement='right'>
                        <li className={`cursor-pointer w-16 ${getDriver() ? 'bg-sky-600 hover:bg-sky-700 text-white' : 'hover:bg-sky-100 text-gray-900'}`} onClick={() => { changeMainMenu('applications') }}>
                            <i className="las la-user-tie w-full text-3xl text-center p-3"></i>
                        </li>
                    </Tippy>
                    {
                        userData.role !== 'MANEGER' && <Tippy content={"Customer Section"} placement='right'>
                            <li className={`cursor-pointer w-16 ${getCutomer() ? 'bg-sky-600 hover:bg-sky-700 text-white' : 'hover:bg-sky-100 text-gray-900'}`} onClick={() => { changeMainMenu('customer') }}>
                                <i className="las la-user-alt w-full text-3xl text-center p-3"></i>
                            </li>
                        </Tippy>
                    }
                    {
                        userData.role !== 'MANEGER' && <Tippy content={"Reports Section"} placement='right'>
                            <li className={`cursor-pointer w-16 ${page === 'report' ? 'bg-sky-600 hover:bg-sky-700 text-white' : 'hover:bg-sky-100 text-gray-900'}`} onClick={() => { changeMainMenu('report') }}>
                                <i className="las la-chart-bar w-full text-3xl text-center p-3"></i>
                            </li>
                        </Tippy>
                    }
                    {
                        userData.role !== 'MANEGER' && <Tippy content={"Staff Section"} placement='right'>
                            <li className={`cursor-pointer w-16 ${getStaff() ? 'bg-sky-600 hover:bg-sky-700 text-white' : 'hover:bg-sky-100 text-gray-900'}`} onClick={() => { changeMainMenu('staff') }}>
                                <i className="las la-briefcase w-full text-3xl text-center p-3"></i>
                            </li>
                        </Tippy>
                    }
                    <Tippy content={"Settings"} placement='right'>
                        <li className={`cursor-pointer w-16 ${page === 'setting' ? 'bg-sky-600 hover:bg-sky-700 text-white' : 'hover:bg-sky-100 text-gray-900'}`} onClick={() => { changeMainMenu('setting') }}>
                            <i className="las la-cog w-full text-3xl text-center p-3"></i>
                        </li>
                    </Tippy>
                    <Tippy content={"Logout"} placement='right'>
                        <li className={`cursor-pointer w-16 ${page === 'logout' ? 'bg-red-600 hover:bg-red-700 text-white' : 'hover:bg-red-400 text-gray-900 hover:text-white'}`} onClick={() => { logout('setting') }}>
                            <i className="las la-sign-out-alt w-full text-3xl text-center p-3"></i>
                        </li>
                    </Tippy>
                </ul>
            </div>
            <div className="fixed bottom-0 bg-white nShadow h-12 w-full rounded-t-md px-2 xs:hidden z-[99]">
                <ul className="flex items-center justify-evenly">
                    {
                        userData.role !== 'MANEGER' && <Tippy content={"Overview Section"} placement='top'>
                            <li className={`cursor-pointer col-span-1 ${getHome() ? 'text-sky-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('overview') }}>
                                <i className="las la-info w-full text-2xl text-center p-2"></i>
                            </li>
                        </Tippy>
                    }
                    <Tippy content={"Driver Section"} placement='top'>
                        <li className={`cursor-pointer col-span-1 ${getDriver() ? 'text-sky-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('applications') }}>
                            <i className="las la-user-tie w-full text-2xl text-center p-2"></i>
                        </li>
                    </Tippy>
                    {
                        userData.role !== 'MANEGER' && <Tippy content={"Customer Section"} placement='top'>
                            <li className={`cursor-pointer col-span-1 ${getCutomer() ? 'text-sky-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('customer') }}>
                                <i className="las la-user-alt w-full text-2xl text-center p-2"></i>
                            </li>
                        </Tippy>
                    }
                    {
                        userData.role !== 'MANEGER' && <Tippy content={"Reports Section"} placement='top'>
                            <li className={`cursor-pointer col-span-1 ${page === 'report' ? 'text-sky-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('report') }}>
                                <i className="las la-chart-bar w-full text-2xl text-center p-2"></i>
                            </li>
                        </Tippy>
                    }
                    {
                        userData.role !== 'MANEGER' && <Tippy content={"Staff Section"} placement='top'>
                            <li className={`cursor-pointer col-span-1 ${page === 'staff' ? 'text-sky-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('staff') }}>
                                <i className="las la-briefcase w-full text-2xl text-center p-2"></i>
                            </li>
                        </Tippy>
                    }
                    <Tippy content={"Settings"} placement='top'>
                        <li className={`cursor-pointer col-span-1 ${page === 'setting' ? 'text-sky-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('setting') }}>
                            <i className="las la-cog w-full text-2xl text-center p-2"></i>
                        </li>
                    </Tippy>
                </ul>
            </div>
        </>
    )
}
