import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import Lottie from 'lottie-react';

import ServiceApplication from '../../../../services/serviceApplication';
import ServiceUtility from '../../../../services/serviceUtility';

import animBtnLoading from '../../../../assets/anim/anim-btnLoading.json';

const allowedExtensions = ["png", "jpeg"];

export default function ComponentStepB(props) {

    const [token] = useRecoilState(tokenAtom);

    const [picFile, setPicFile] = useState(null);
    const [picPreview, setPicFilePreview] = useState(null);

    const [submit, setSubmit] = useState(false);

    const alert = useAlert();
    const fileService = new ServiceUtility();
    const appService = new ServiceApplication();

    const formVSchema = Yup.object().shape({
        gardPhone: Yup.string().matches(/^[0-9]{10}$/, { message: 'Please enter valid phone number' }),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            gardName: '',
            gardPhone: '',
            gardPhoneb: '',
            gardaddress: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            if (props.data.data.guardianInfo) {
                if (picFile === null) {
                    let body = {
                        "fid": props.data._id,
                        "opration": "updateStepB",
                        "data": {
                            "gardName": values.gardName,
                            "gardPhone": values.gardPhone,
                            "gardPhoneb": values.gardPhoneb,
                            "gardaddress": values.gardaddress,
                            "gardPic": props.data.data.guardianInfo.gardPic,
                        }
                    }
                    appService.updateFormA(body, token).then((res) => {
                        setSubmit(false);
                        if (res.status) {
                            alert.show("Application updated!", { type: 'success' });
                            props.gotoNext(3, res.data);
                        } else {
                            alert.show("Server error, please try again!", { type: 'error' });
                        }
                    });
                } else {
                    var formDataA = new FormData();
                    formDataA.append("path", props.data.data.guardianInfo.gardPic.fileurl);
                    formDataA.append("doc", picFile);

                    fileService.replaceFile(formDataA, token).then((res) => {
                        if (res.status) {
                            let body = {
                                "fid": props.data._id,
                                "opration": "updateStepB",
                                "data": {
                                    "gardName": values.gardName,
                                    "gardPhone": values.gardPhone,
                                    "gardPhoneb": values.gardPhoneb,
                                    "gardaddress": values.gardaddress,
                                    "gardPic": res.data,
                                }
                            }
                            appService.updateFormA(body, token).then((res) => {
                                setSubmit(false);
                                if (res.status) {
                                    alert.show("Application updated!", { type: 'success' });
                                    props.gotoNext(3, res.data);
                                } else {
                                    alert.show("Server error, please try again!", { type: 'error' });
                                }
                            });
                        } else {
                            setSubmit(false);
                            alert.show("Server error, please try again!", { type: 'error' });
                        }
                    });
                }
            } else {
                if (picFile === null) {
                    let body = {
                        "fid": props.data._id,
                        "opration": "updateStepB",
                        "data": {
                            "gardName": values.gardName,
                            "gardPhone": values.gardPhone,
                            "gardPhoneb": values.gardPhoneb,
                            "gardaddress": values.gardaddress,
                            "gardPic": {},
                        }
                    }
                    appService.updateFormA(body, token).then((res) => {
                        setSubmit(false);
                        if (res.status) {
                            alert.show("Application updated!", { type: 'success' });
                            props.gotoNext(3, res.data);
                        } else {
                            alert.show("Server error, please try again!", { type: 'error' });
                        }
                    });
                } else {
                    var formDataA = new FormData();
                    formDataA.append("path", 'guardians');
                    formDataA.append("doc", picFile);

                    fileService.uploadFile(formDataA, token).then((res) => {
                        if (res.status) {
                            let body = {
                                "fid": props.data._id,
                                "opration": "updateStepB",
                                "data": {
                                    "gardName": values.gardName,
                                    "gardPhone": values.gardPhone,
                                    "gardPhoneb": values.gardPhoneb,
                                    "gardaddress": values.gardaddress,
                                    "gardPic": res.data,
                                }
                            }
                            appService.updateFormA(body, token).then((res) => {
                                setSubmit(false);
                                if (res.status) {
                                    alert.show("Application updated!", { type: 'success' });
                                    props.gotoNext(3, res.data);
                                } else {
                                    alert.show("Server error, please try again!", { type: 'error' });
                                }
                            });
                        } else {
                            setSubmit(false);
                            alert.show("Server error, please try again!", { type: 'error' });
                        }
                    });
                }
            }
        },
    });

    const handleFileChange = (e) => {
        var { value, name } = e.target;
        let isError = false;

        const inputFile = e.target.files[0];
        const fileExtension = inputFile?.type.split("/")[1];

        if (!allowedExtensions.includes(fileExtension)) {
            alert.show("Only .png file format is allowed, please try again!", { type: 'error' });
            isError = true;
        }

        if (isError) {
            return;
        }

        setPicFile(inputFile);

        const reader = new FileReader();
        reader.onload = () => {
            setPicFilePreview(reader.result);
        };
        reader.readAsDataURL(inputFile);
    };

    useEffect(() => {
        if (props.data.data.guardianInfo) {
            setValues({ ...values, ...props.data.data.guardianInfo });
        }
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <div className="mt-4 grid grid-cols-12 gap-6">
                <div className="intro-y mt-5 md:mt-0 col-span-12">
                    <div className="shadow">
                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Tutor Information <span className='text-xs text-gray-400'>(optional)</span></h3>
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Wife's / Tutor Name <span className="text-gray-400">(Optional)</span></label>
                                    <input type="text" id="gardName" value={values.gardName} onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Full name" />
                                    {(errors.gardName && touched.gardName) && <p className='text-xs text-red-400 mt-1'>{errors.gardName}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Wife's / Tutor Phone number <span className="text-gray-400">(Optional)</span></label>
                                    <input type="text" id="gardPhone" value={values.gardPhone} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 012345689" maxLength="10" minLength="10" />
                                    {(errors.gardPhone && touched.gardPhone) && <p className='text-xs text-red-400 mt-1'>{errors.gardPhone}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Wife's / Tutor Telephone number <span className="text-gray-400">(Optional)</span></label>
                                    <input type="text" id="gardPhoneb" value={values.gardPhoneb} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 012345689" maxLength="10" minLength="10" />
                                    {(errors.gardPhoneb && touched.gardPhoneb) && <p className='text-xs text-red-400 mt-1'>{errors.gardPhoneb}</p>}
                                </div>

                                <div className="col-span-6">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Physical address 1 <span className="text-gray-400">(Optional)</span></label>
                                    <textarea id="gardaddress" value={values.gardaddress} onChange={handleChange} autoComplete="street-address" className="mt-1 p-2 block w-full h-20 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Street address"></textarea>
                                    {(errors.gardaddress && touched.gardaddress) && <p className='text-xs text-red-400 mt-1'>{errors.gardaddress}</p>}
                                </div>

                                <div className="col-span-6 md:col-span-2">

                                    <div className="py-2 bg-white">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Wife's / Tutor Photo document <span className='text-xs text-gray-400'>(optional)</span>
                                            </label>
                                            {
                                                (picFile === null && !props.data.data.guardianInfo) && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                    <div className="space-y-1 text-center">
                                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <div className="flex text-sm text-gray-600">
                                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                <span>Upload a file</span>
                                                                <input type="file" name='pFile' className="sr-only" onChange={handleFileChange} />
                                                            </label>
                                                            <p className="pl-1">or drag and drop</p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">
                                                            PNG, JPG, PDF up to 1MB
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                picFile !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                    <div className="w-full flex items-center justify-center py-4">
                                                        <img src={picPreview} className='w-[400px] h-[400px]' />
                                                    </div>
                                                    <hr />
                                                    <div className="flex justify-between mt-2">
                                                        <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                            <span>View</span>
                                                        </label>
                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                            <span>Replace</span>
                                                            <input type="file" name='pFile' className="sr-only" onChange={handleFileChange} />
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (picFile === null && props.data.data.guardianInfo) && <>
                                                    {
                                                        !props.data.data.guardianInfo.gardPic && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                            <div className="space-y-1 text-center">
                                                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <div className="flex text-sm text-gray-600">
                                                                    <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                        <span>Upload a file</span>
                                                                        <input type="file" name='pFile' className="sr-only" onChange={handleFileChange} />
                                                                    </label>
                                                                    <p className="pl-1">or drag and drop</p>
                                                                </div>
                                                                <p className="text-xs text-gray-500">
                                                                    PNG, JPG, PDF up to 1MB
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        props.data.data.guardianInfo.gardPic && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                            <div className="w-full flex items-center justify-center py-4">
                                                                <img src={props.data.data.guardianInfo.gardPic.fileurl} className='w-[400px] h-[400px]' />
                                                            </div>
                                                            <hr />
                                                            <div className="flex justify-between mt-2">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                                    <span>View</span>
                                                                </label>
                                                                <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                    <span>Replace</span>
                                                                    <input type="file" name='pFile' className="sr-only" onChange={handleFileChange} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-y hidden sm:block">
                <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                </div>
            </div>
            <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700">
                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                    {!submit && "SAVE & NEXT"}
                </button>
            </div>
            <div className='h-[60px] md:hidden'></div>
        </form>
    )
}
