import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom, userAtom } from '../../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import Lottie from 'lottie-react';

import ServiceApplication from '../../../../services/serviceApplication';

import animBtnLoading from '../../../../assets/anim/anim-btnLoading.json';

export default function ComponentStepA(props) {

    const [token] = useRecoilState(tokenAtom);
    const [user] = useRecoilState(userAtom);

    const [submit, setSubmit] = useState(false);

    const alert = useAlert();
    const appService = new ServiceApplication();

    const formVSchema = Yup.object().shape({
        fname: Yup.string().required('This information is required'),
        lname: Yup.string().required('This information is required'),
        email: Yup.string().email('Please enter valid email address'),
        phone: Yup.string().matches(/^[0-9]{10}$/, { message: 'Please enter valid phone number' }).required('This information is required'),
        dob: Yup.string().required('This information is required'),
        address: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            fname: '',
            mname: '',
            lname: '',
            email: '',
            phone: '',
            phoneb: '',
            dob: '',
            mStatus: '',
            noc: '',
            address: '',
            addressb: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            if (props.isEdit) {
                let body = {
                    "fid": props.data._id,
                    "opration": "updateStepA",
                    "data": {
                        "fname": values.fname,
                        "mname": values.mname,
                        "lname": values.lname,
                        "email": values.email,
                        'phone': values.phone,
                        'phoneb': values.phoneb,
                        'dob': values.dob,
                        'mStatus': values.mStatus,
                        'noc': values.noc,
                        'address': values.address,
                        'addressb': values.addressb,
                    }
                }
                appService.updateFormA(body, token).then((res) => {
                    if (res.status) {
                        setSubmit(false);
                        alert.show("Application updated!", { type: 'success' });
                        props.gotoNext(2, res.data);
                    } else {
                        setSubmit(false);
                        alert.show("Server error, please try again!", { type: 'error' });
                    }
                });
            } else {
                let body = {
                    "aid": user._id,
                    "data": {
                        "fname": values.fname,
                        "mname": values.mname,
                        "lname": values.lname,
                        "email": values.email,
                        'phone': values.phone,
                        'phoneb': values.phoneb,
                        'dob': values.dob,
                        'mStatus': values.mStatus,
                        'noc': values.noc,
                        'address': values.address,
                        'addressb': values.addressb
                    }
                }
                appService.createForm1A(body, token).then((res) => {
                    if (res.status) {
                        setSubmit(false);
                        if (res.statuscode === 202) {
                            alert.show(res.msg, { type: 'error' });
                        } else {
                            alert.show("New application added!", { type: 'success' });
                            props.gotoNext(2, res.data);
                        }
                    } else {
                        setSubmit(false);
                        alert.show("Server error, please try again!", { type: 'error' });
                    }
                });
            }
        },
    });

    useEffect(() => {
        if (props.isEdit) {
            setValues({ ...values, ...props.data.data.personalInfo });
        }
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <div className="mt-4 grid grid-cols-12 gap-6">
                <div className="intro-y mt-5 md:mt-0 col-span-12">
                    <div className="shadow">
                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">First name<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="fname" value={values.fname} onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="First name" />
                                    {(errors.fname && touched.fname) && <p className='text-xs text-red-400 mt-1'>{errors.fname}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Middle name <span className="text-gray-400">(Optional)</span></label>
                                    <input type="text" id="mname" value={values.mname} onChange={handleChange} autoComplete="family-name" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Middle name" />
                                    {(errors.mname && touched.mname) && <p className='text-xs text-red-400 mt-1'>{errors.mname}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Last name<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="lname" value={values.lname} onChange={handleChange} autoComplete="family-name" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Last name" />
                                    {(errors.lname && touched.lname) && <p className='text-xs text-red-400 mt-1'>{errors.lname}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Email address</label>
                                    <input type="text" id="email" value={values.email} onChange={handleChange} autoComplete="email" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Email address" />
                                    {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Phone number 1<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="phone" value={values.phone} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 012345689" maxLength="10" minLength="10" />
                                    {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Phone number 2 <span className="text-gray-400">(Optional)</span></label>
                                    <input type="text" id="phoneb" value={values.phoneb} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 012345689" maxLength="10" minLength="10" />
                                    {(errors.phoneb && touched.phoneb) && <p className='text-xs text-red-400 mt-1'>{errors.phoneb}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Date of birth<sup className="text-red-600">*</sup></label>
                                    <div className="relative">
                                        <input type="date" id="dob" value={values.dob} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" />
                                    </div>
                                    {(errors.dob && touched.dob) && <p className='text-xs text-red-400 mt-1'>{errors.dob}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Marital Status <span className="text-gray-400">(Optional)</span></label>
                                    <select className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" id="mStatus" onChange={handleChange} value={values.mStatus}>
                                        <option value="">Select Status</option>
                                        <option value="1">Single</option>
                                        <option value="2">Married</option>
                                        <option value="3">Widowed</option>
                                        <option value="4">Divorced</option>
                                    </select>
                                    {(errors.mStatus && touched.mStatus) && <p className='text-xs text-red-400 mt-1'>{errors.mStatus}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Number of children <span className="text-gray-400">(Optional)</span></label>
                                    <input type="number" id="noc" value={values.noc} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 1" maxLength="10" minLength="10" />
                                    {(errors.noc && touched.noc) && <p className='text-xs text-red-400 mt-1'>{errors.noc}</p>}
                                </div>

                                <div className="col-span-6">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Physical address 1<sup className="text-red-600">*</sup></label>
                                    <textarea id="address" value={values.address} onChange={handleChange} autoComplete="street-address" className="mt-1 p-2 block w-full h-20 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Street address"></textarea>
                                    {(errors.address && touched.address) && <p className='text-xs text-red-400 mt-1'>{errors.address}</p>}
                                </div>

                                <div className="col-span-6">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Physical address 2 <span className="text-gray-400">(Optional)</span></label>
                                    <textarea id="addressb" value={values.addressb} onChange={handleChange} autoComplete="street-address" className="mt-1 p-2 block w-full h-20 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Street address"></textarea>
                                    {(errors.addressb && touched.addressb) && <p className='text-xs text-red-400 mt-1'>{errors.addressb}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-y hidden sm:block">
                <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                </div>
            </div>
            <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700">
                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                    {!submit && "SAVE & NEXT"}
                </button>
            </div>
            <div className='h-[60px] md:hidden'></div>
        </form>
    )
}
