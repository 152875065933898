import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { motion } from "framer-motion";
import { Scrollbars } from 'react-custom-scrollbars';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import { useAlert } from 'react-alert';
import Moment from 'moment';
import * as XLSX from 'xlsx';
import { Menu, Transition } from '@headlessui/react';
import Lottie from 'lottie-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useRecoilState } from 'recoil';
import { tokenAtom, langAtom } from '../../../core/config/atoms';

import TopBar from '../../../core/widgets/ui/TopBar';

import ServiceSubscription from '../../../services/serviceSubscription';
import ServicePlan from '../../../services/servicePlan';

import animPage from '../../../assets/anim/anim-pageload.json';
import animBtnLoading from '../../../assets/anim/anim-btnLoading.json';

export default function PanelSubscriptionList(props) {

    const [token] = useRecoilState(tokenAtom);
    const [lang] = useRecoilState(langAtom);

    const [subs, setSubs] = useState([]);
    const [members, setMembers] = useState([]);

    const [filter, setFilter] = useState({});
    const [subsa, setSubsa] = useState({});

    const [sdate, setSDate] = useState(Moment().startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');
    const [perr, setPerr] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isDate, setIsDate] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [selection, setSelection] = useState(false);

    const alert = useAlert();
    const navigate = useNavigate();
    const subService = new ServiceSubscription();
    const planService = new ServicePlan();

    const formVSchema = Yup.object().shape({
        plan: Yup.string().required('This information is required'),
        mode: Yup.string().required('This information is required'),
        ref: Yup.string().required('This information is required'),
    });

    const getMembership = (mid) => {
        return members.filter((a) => a._id === mid)[0];
    }

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            'plan': '',
            'mode': '',
            'ref': ''
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            var body = {
                'sid': subsa._id,
                'uid': subsa.uid,
                'rate': getMembership(values.plan).rate,
                'tunure': getMembership(values.plan).tenuare,
                'mode': values.mode,
                'pref': values.ref,
            }
            subService.updateSubscription(body, token).then((res) => {
                if (res.status) {
                    alert.show("Subscription updated!", { type: 'success' });
                } else {
                    alert.show("Server error, please try again!", { type: 'error' });
                }
                setSubmit(false);
                setSubsa({});
                setSelection(false);
                loadData();
            });
        }
    });

    const changeTab = (val) => {
        setTab(val);
        setQuery('');
        setSDate(Moment().startOf('year').toDate());
        setEDate(Moment().toDate());
        switch (val) {
            case "all":
                setFilter({});
                break;
            case "active":
                setFilter({ 'status': 'active' });
                break;
            case "expired":
                setFilter({ 'status': 'expired' });
                break;
            default:
                break;
        }
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const getExportItems = () => {
        let items = [];
        subs.forEach((item) => {
            items.push({
                mid: item.mid,
                fname: item.user.personalInfo.fname,
                mname: item.user.personalInfo.mname,
                lname: item.user.personalInfo.lname,
                email: item.user.personalInfo.email,
                startDate: item.sdate,
                endDate: item.edate,
                status: item.status,
                createdAt: item.createdAt,
            });
        });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "subscription");
        XLSX.writeFile(workbook, "subscription.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['mid,fname,mname,lname,email,startDate,endDate,status,createdAt'];
        let usersCsv = subs.reduce((acc, user) => {
            const { mid, sdate, edate, status, createdAt } = user
            acc.push([mid, user.user.personalInfo.fname, user.user.personalInfo.mname, user.user.personalInfo.lname, user.user.personalInfo.email, sdate, edate, status, createdAt].join(','))
            return acc
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'subscription.csv',
            fileType: 'text/csv',
        });
    }

    const onDateChange = (start, end) => {
        setIsDate(true);
        setSDate(start);
        setEDate(end);
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await subService.getAllSubscription(body, token);
            if (res['status']) {
                var bodyPlan = { 'lang': lang };
                var resPlan = await planService.getPlans(bodyPlan, token);
                setMembers(resPlan.data);
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setSubs(res.data.data);
                resetForm();
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await subService.getAllSubscription(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setSubs(res.data.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, sdate, edate, filter]);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                selection && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] absolute right-0 h-screen p-[20px]'>
                        <Scrollbars>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900'>Add Payment</h1>
                                    <span onClick={() => {
                                        setSelection(false);
                                        loadData();
                                        resetForm();
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <hr className='mt-2' />
                                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} autoComplete="off">
                                    <div className="mb-4">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Subscription Plan<sup className="text-red-600">*</sup></label>
                                        <select type="text" name="plan" id="plan" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} value={values.plan}>
                                            <option value="">-- Select Plan --</option>
                                            {
                                                members.map((plan) => {
                                                    return <option value={plan._id} selected={plan._id == values.plan}>{plan.title} - ${plan.rate} / {plan.tenuare} Days</option>
                                                })
                                            }
                                        </select>
                                        {(errors.plan && touched.plan) && <p className='text-xs text-red-400 mt-1'>{errors.plan}</p>}
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Payment Mode<sup className="text-red-600">*</sup></label>
                                        <select type="text" name="mode" id="mode" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. CLEANERS" onChange={handleChange} value={values.mode}>
                                            <option value="">-- Select Mode --</option>
                                            <option value="bank" selected={"bank" == values.mode}>Bank Transfer</option>
                                            <option value="online" selected={"online" == values.mode}>Online Transfer</option>
                                            <option value="M2M" selected={"M2M" == values.mode}>Mobile number Transfer</option>
                                            <option value="cheque" selected={"cheque" == values.mode}>Cheque Transfer</option>
                                            <option value="cash" selected={"cash" == values.mode}>Cash Collection</option>
                                        </select>
                                        {(errors.mode && touched.mode) && <p className='text-xs text-red-400 mt-1'>{errors.mode}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Payment Reference<sup className="text-red-600">*</sup></label>
                                        <input type="text" value={values.ref} name="ref" id="ref" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. XXXXXXX" onChange={handleChange} />
                                        {(errors.ref && touched.ref) && <p className='text-xs text-red-400 mt-1'>{errors.ref}</p>}
                                    </div>
                                    <div className='flex items-end'>
                                        <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                            {!submit && "Add"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            }
            <Scrollbars>
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10">
                            <h2 class="text-2xl font-medium truncate ml-2">
                                <motion.i whileHover={{ scale: 1.2 }} className="las la-bars cursor-pointer" onClick={() => { props.onToggle() }}></motion.i> Subscriptions
                            </h2>
                            <div className='flex'>
                                <TopBar />
                            </div>
                        </div>
                        <div class="w-full mt-2">
                            <div className="p-2 mt-2">
                                <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'all' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('all') }}>All</button></li>
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'active' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('active') }}>Active</button></li>
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'expired' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('expired') }}>Expired</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 mt-4 px-2">
                    <div className="w-full shadow-md rounded-md border">
                        <table className="min-w-full divide-y divide-gray-200 px-4">
                            <thead className="bg-gray-50">
                                <tr className=''>
                                    <th scope="col" className="border-b border-gray-200" colSpan={7}>
                                        <div className='w-full grid grid-cols-12 gap-2'>
                                            <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                <div className='col-span-4'>
                                                    <RangeDatePicker
                                                        startDate={sdate}
                                                        endDate={edate}
                                                        minDate={new Date(2022, 8, 1)}
                                                        maxDate={Moment().toDate()}
                                                        disabled={false}
                                                        onCloseCalendar={(e) => {
                                                            loadData();
                                                        }}
                                                        onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                        className=""
                                                        startWeekDay="monday"
                                                    />
                                                </div>
                                                <div className='w-full flex col-span-8 items-center'>
                                                    <div className='w-[80px]'>
                                                        <p>Search :</p>
                                                    </div>
                                                    <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by name and email." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                </div>
                                            </form>
                                            <div className='col-span-1 flex items-center'>
                                                <Menu>
                                                    <Menu.Button>
                                                        <button className="w-full py-1 px-2 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                    </Menu.Button>
                                                    <Transition
                                                        enter="transition duration-100 ease-out"
                                                        enterFrom="transform scale-95 opacity-0"
                                                        enterTo="transform scale-100 opacity-100"
                                                        leave="transition duration-75 ease-out"
                                                        leaveFrom="transform scale-100 opacity-100"
                                                        leaveTo="transform scale-95 opacity-0">
                                                        <Menu.Items>
                                                            <div
                                                                className='bg-white shadow-md rounded-md absolute' style={{ top: '32px', left: '-176px' }}>
                                                                <div>
                                                                    <ul className='flex flex-col items-start p-2'>
                                                                        <Menu.Item>
                                                                            <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                exportToExcel();
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                                </div>
                                                                            </li>
                                                                        </Menu.Item>
                                                                        <Menu.Item>
                                                                            <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                exportToCsv();
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                                </div>
                                                                            </li>
                                                                        </Menu.Item>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Membership ID
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Subscriber Details
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Start Date
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        End Date
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Created At
                                    </th>
                                    <th scope="col" className="px-2 py-3"></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    subs.length !== 0 && subs.map((sub) => {
                                        return <tr className="hover:bg-gray-100">
                                            <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                                <div className="text-sm font-bold text-gray-900">
                                                    <div className='flex items-center text-blue-600 uppercase'>
                                                        {sub.mid}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-600">
                                                    <p>User Name: <span className='font-bold text-gray-900'>{sub.user.personalInfo.fname} - {sub.user.personalInfo.mname} - {sub.user.personalInfo.lname}</span></p>
                                                    <p>User Email: <span className='font-bold text-gray-900'>{sub.user.personalInfo.email}</span></p>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-blue-600">
                                                    {Moment(sub.sdate).format('DD, MMM yyyy')}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-blue-600">
                                                    {Moment(sub.edate).format('DD, MMM yyyy')}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${sub.status == 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                                    {sub.status.toUpperCase()}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-blue-600">
                                                    {Moment(sub.createdAt).format('DD, MMM yyyy')}
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    <Menu>
                                                        <Menu.Button>
                                                            <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                        </Menu.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0">
                                                            <Menu.Items>
                                                                <div
                                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-180px' }}>
                                                                    <div>
                                                                        <ul className='flex flex-col items-start p-2'>
                                                                            {
                                                                                <Menu.Item>
                                                                                    <li className='w-48 cursor-pointer hover:bg-green-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                        setSubsa(sub);
                                                                                        setSelection(true);
                                                                                    }}>
                                                                                        <div className='flex items-center text-base'>
                                                                                            <i className="las la-credit-card text-lg"></i>
                                                                                            <p className='text-sm font-serif ml-2'>Add Payment</p>
                                                                                        </div>
                                                                                    </li>
                                                                                </Menu.Item>
                                                                            }
                                                                            {
                                                                                <Menu.Item>
                                                                                    <li className='w-48 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                        //Todo: add option
                                                                                    }}>
                                                                                        <div className='flex items-center text-base'>
                                                                                            <i className="las la-ban text-lg"></i>
                                                                                            <p className='text-sm font-serif ml-2'>Cancel Membership</p>
                                                                                        </div>
                                                                                    </li>
                                                                                </Menu.Item>
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                }
                                            </td>
                                        </tr>
                                    })
                                }
                                {
                                    subs.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                        <td className="px-6 py-4 whitespace-nowrap" colSpan="7">
                                            <div className="text-sm text-blue-600 text-center">
                                                No data found.
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <div className='flex py-2 px-4 items-center bg-blue-50'>
                            <p className='text-sm font-serif'>Show rows per page</p>
                            <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                            </select>
                            <div className='flex-grow'></div>
                            <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                            <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                            <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div >
    )
}
