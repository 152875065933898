import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { motion } from "framer-motion";
import { Scrollbars } from 'react-custom-scrollbars';
import Lottie from 'lottie-react';

import animPage from '../../assets/anim/anim-pageload.json';

import TopBar from '../../core/widgets/ui/TopBar';

import ComponentMemberTotalCard from './components/card/ComponentMemberTotalCard';
import ComponentMemberNumCard from './components/card/ComponentMemberNumCard';
import ComponentDriverNumCard from './components/card/ComponentDriverNumCard';
import ComponentVahicleNumCard from './components/card/ComponentVahicleNumCard';
import ComponentCustomerNumCard from './components/card/ComponentCustomerNumCard';

import ComponentApplicationList from './components/list/ComponentApplicationList';
import ComponentExpiryList from './components/list/ComponentExpiryList';

export default function PanelOverview(prpos) {

    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    const loadData = () => {
        (async function () {
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <div className="intro-y flex items-center justify-between h-10">
                <h2 className="text-2xl font-medium truncate ml-2">
                    <motion.i whileHover={{ scale: 1.2 }} className="las la-bars cursor-pointer" onClick={() => { prpos.onToggle(); }}></motion.i> Overview
                </h2>
                <div className='z-50'>
                    <TopBar />
                </div>
            </div>
            <hr className='mt-2' />
            {
                !loading && <Scrollbars>
                    <div className="grid grid-cols-4 lg:grid-cols-10 mt-6 mb-5 gap-2 xl:gap-5 ml-0 md:ml-2 px-4">
                        <ComponentMemberTotalCard />
                        <ComponentMemberNumCard />
                        <ComponentDriverNumCard />
                        <ComponentVahicleNumCard />
                        <ComponentCustomerNumCard />
                    </div>
                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-0 md:ml-2 px-4'>
                        <ComponentApplicationList />
                    </div>
                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-0 md:ml-2 px-4'>
                        <ComponentExpiryList />
                    </div>
                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-0 md:ml-2'></div>
                </Scrollbars>
            }
        </div>
    )
}
