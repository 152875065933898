import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { formAtom, tokenAtom } from '../../../core/config/atoms';

import { useAlert } from 'react-alert';
import { Scrollbars } from 'react-custom-scrollbars';
import Lottie from 'lottie-react';
import Moment from 'moment';
import { Dialog } from '@headlessui/react';

import animBtnLoading from '../../../assets/anim/anim-btnLoading.json';

import ServiceApplication from '../../../services/serviceApplication';
import ServiceDriver from '../../../services/serviceDriver';

export default function PanelCR1Verification(props) {
    const [form] = useRecoilState(formAtom);
    const [token] = useRecoilState(tokenAtom);

    const [driver, setDriver] = useState({});

    const [url, setURL] = useState('');

    const [loading, setLoading] = useState(true);
    const [submit, setSubmit] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();
    const alert = useAlert();

    const appService = new ServiceApplication();
    const driverService = new ServiceDriver();

    const goBack = () => {
        navigate(`/home/applications`);
    }

    const getIdType = (type) => {
        switch (type) {
            case "1":
                return "Tax ID";
            case "2":
                return "Address Prof";
            case "3":
                return "Driver Licence";
            case "4":
                return "Passport";
            default:
                return "NA";
        }
    }

    const viewDocument = (fileurl) => {
        setURL(fileurl);
        setIsOpen(true);
    }

    const getmStatus = (type) => {
        switch (type) {
            case "1":
                return "Single";
            case "2":
                return "Married";
            case "3":
                return "Widowed";
            case "4":
                return "Divorced";
            default:
                return "NA";
        }
    }

    const Reject = () => {
        setSubmit(true);
        let body = {
            fid: form._id,
        }
        appService.deleteApplication(body, token).then((res) => {
            setSubmit(false);
            if (res['status']) {
                alert.show('Application rejected!', { type: 'success' });
                goBack();
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        });
    }

    const Accept = () => {
        setSubmit(true);
        let body = {
            "fid": form._id,
        }
        appService.acceptFormCR1(body, token).then((res) => {
            setSubmit(false);
            if (res.status) {
                alert.show("Application approved!", { type: 'success' });
                goBack();
            } else {
                alert.show("Server error, please try again!", { type: 'error' });
            }
        });
    }

    const loadData = (ln) => {
        (async function () {
            let body = { "uid": form.applicantId }
            var res = await driverService.getDriverById(body, token);
            if (res['status']) {
                setDriver(res.data);
            }
            setLoading(false);
        })();
    }

    useEffect(() => {
        props.onTap();
        loadData('en');
    }, []);

    return (
        <div className="w-full h-screen p-2 flex flex-col bg-gray-50">
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-4xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Document viewer &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpen(false);
                        }}>
                            <i className="las la-times text-sm"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <div>
                        <img src={url} alt='Document' className='w-full rounded border-2' />
                    </div>
                    <hr className='my-2' />
                </div>
            </Dialog>
            {
                !loading && <Scrollbars>
                    <div className="grid grid-cols-12 grid-gap-2 p-3 overflow-y-scroll">
                        <div className='col-span-12'>
                            <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                                <i className="las la-long-arrow-alt-left text-xl"></i>
                                <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all applications</h4>
                            </div>
                            <div className="intro-y mt-4">
                                <h3 className="text-2xl font-sans font-bold text-gray-800">Taxi Driver : {form.appType}</h3>
                                <p className="mt-1 text-sm text-gray-600 mb-2">
                                    Please review all information related to driver.
                                </p>
                                <hr />
                            </div>
                        </div>
                    </div>
                    <div className='mx-8'>
                        <div className="col-span-12 mt-4">
                            <div className="intro-y w-full shadow-lg">
                                <div className='h-28 bg-sky-600 rounded-t-lg relative'>
                                    <div className='bg-sky-600 w-24 h-24 rounded-full flex items-center justify-center border-4 border-white absolute' style={{ bottom: '-3rem', left: '1rem' }}>
                                        <h1 className='text-white text-3xl uppercase'>{driver.personalInfo.fname[0]}{driver.personalInfo.lname[0]}</h1>
                                    </div>
                                </div>
                                <div className='pb-4 px-4 mb-4 rounded-b-lg'>
                                    <div className='text-2xl font-bold ml-28'>{form.applicantName}</div>
                                    <div className='text-lg text-gray-600 ml-28'>{form.appType}</div>
                                </div>
                            </div>
                        </div>
                        <hr className='my-4' />
                        <div className='grid grid-cols-12 gap-8 w-full'>
                            <div className='col-span-4 shadow-lg bg-white p-4 rounded-md' style={{ height: 'fit-content' }}>
                                <h1 className='text-black font-medium text-xl'>Driver Information</h1>
                                <hr className='my-1' />
                                <div className='flex items-center mt-2'>
                                    <img className="w-36 h-36 rounded border border-slate-950 mr-3 object-cover" src={driver.frontPic.fileurl} />
                                    <img className="w-36 h-36 rounded border border-slate-950 mx-3 object-cover" src={driver.smilePic.fileurl} />
                                    <img className="w-36 h-36 rounded border border-slate-950 mx-3 object-cover" src={driver.profilePic.fileurl} />
                                </div>
                                <div className="text-black text-lg font-medium font-['Rubik'] mt-2">{driver.personalInfo.fname} {driver.personalInfo.mname} {driver.personalInfo.lname}</div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik'] mt-2">Email ID:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{driver.personalInfo.email === "" ? "NA" : driver.personalInfo.email}</span></div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik']">DOB:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{driver.personalInfo.dob === "" ? "NA" : Moment(driver.personalInfo.dob, 'yyyy-MM-DD').format('DD MMM yyyy')}</span></div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik']">Phone primary:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{driver.personalInfo.phone === "" ? "NA" : "+243 " + driver.personalInfo.phone}</span></div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik']">Phone secondary:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{driver.personalInfo.phoneb === "" ? "NA" : "+243 " + driver.personalInfo.phoneb}</span></div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik']">Marital Status:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{getmStatus(driver.personalInfo.mStatus)}</span></div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik']">Number of children:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{driver.personalInfo.noc === "" ? "NA" : driver.personalInfo.noc}</span></div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik']">Physical Address 1</div>
                                <div className="text-slate-400 text-sm font-normal font-['Rubik']">{driver.personalInfo.address === "" ? "NA" : driver.personalInfo.address}</div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik']">Physical Address 2</div>
                                <div className="text-slate-400 text-sm font-normal font-['Rubik']">{driver.personalInfo.addressb === "" ? "NA" : driver.personalInfo.addressb}</div>
                                <div className="flex items-center justify-between mt-2">
                                    <div>
                                        <div className="text-slate-950 text-sm font-normal font-['Rubik']">{getIdType(driver.identityType)}</div>
                                        <div className="text-slate-400 text-sm font-normal font-['Rubik']">{driver.identityNumber}</div>
                                    </div>
                                    <div className="text-blue-600 text-sm font-medium font-['Rubik'] cursor-pointer" onClick={() => { viewDocument(driver.identityDoc.fileurl); }}>View</div>
                                </div>
                            </div>
                            <div className='col-span-4 shadow-lg bg-white p-4 rounded-md' style={{ height: 'fit-content' }}>
                                <h1 className='text-black font-medium text-xl'>Original Information</h1>
                                <hr className='my-1' />
                                <div className="text-black text-lg font-medium font-['Rubik'] mt-2">Name: {driver.personalInfo.fname} {driver.personalInfo.mname} {driver.personalInfo.lname}</div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik'] mt-2">Email ID:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{driver.personalInfo.email === "" ? "NA" : driver.personalInfo.email}</span></div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik']">DOB:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{driver.personalInfo.dob === "" ? "NA" : Moment(driver.personalInfo.dob, 'yyyy-MM-DD').format('DD MMM yyyy')}</span></div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik']">Phone primary:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{driver.personalInfo.phone === "" ? "NA" : "+243 " + driver.personalInfo.phone}</span></div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik']">Phone secondary:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{driver.personalInfo.phoneb === "" ? "NA" : "+243 " + driver.personalInfo.phoneb}</span></div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik']">Marital Status:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{getmStatus(driver.personalInfo.mStatus)}</span></div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik']">Number of children:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{driver.personalInfo.noc === "" ? "NA" : driver.personalInfo.noc}</span></div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik']">Physical Address 1</div>
                                <div className="text-slate-400 text-sm font-normal font-['Rubik']">{driver.personalInfo.address === "" ? "NA" : driver.personalInfo.address}</div>
                                <div className="text-slate-950 text-sm font-normal font-['Rubik']">Physical Address 2</div>
                                <div className="text-slate-400 text-sm font-normal font-['Rubik']">{driver.personalInfo.addressb === "" ? "NA" : driver.personalInfo.addressb}</div>
                            </div>
                            <div className='col-span-4 shadow-lg bg-white p-4 rounded-md' style={{ height: 'fit-content' }}>
                                <h1 className='text-black font-medium text-xl'>Update Requested</h1>
                                <hr className='my-1' />
                                {(form.data.personalInfo.fname || form.data.personalInfo.mname || form.data.personalInfo.lname) && <div className="text-black text-lg font-medium font-['Rubik'] mt-2">Name: {form.data.personalInfo.fname} {form.data.personalInfo.mname} {form.data.personalInfo.lname}</div>}
                                {form.data.personalInfo.email && <div className="text-slate-950 text-sm font-normal font-['Rubik'] mt-2">Email ID:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{form.data.personalInfo.email}</span></div>}
                                {form.data.personalInfo.dob && <div className="text-slate-950 text-sm font-normal font-['Rubik']">DOB:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{Moment(driver.personalInfo.dob, 'yyyy-MM-DD').format('DD MMM yyyy')}</span></div>}
                                {form.data.personalInfo.phone && <div className="text-slate-950 text-sm font-normal font-['Rubik']">Phone primary:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">+243 {form.data.personalInfo.phone}</span></div>}
                                {form.data.personalInfo.phoneb && <div className="text-slate-950 text-sm font-normal font-['Rubik']">Phone secondary:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">+243 {form.data.personalInfo.phoneb}</span></div>}
                                {form.data.personalInfo.mStatus && <div className="text-slate-950 text-sm font-normal font-['Rubik']">Marital Status:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{getmStatus(driver.personalInfo.mStatus)}</span></div>}
                                {form.data.personalInfo.noc && <div className="text-slate-950 text-sm font-normal font-['Rubik']">Number of children:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{form.data.personalInfo.noc}</span></div>}
                                {
                                    form.data.personalInfo.address && <>
                                        <div className="text-slate-950 text-sm font-normal font-['Rubik']">Physical Address 1</div>
                                        <div className="text-slate-400 text-sm font-normal font-['Rubik']">{form.data.personalInfo.address}</div>
                                    </>
                                }
                                {
                                    form.data.personalInfo.addressb && <>
                                        <div className="text-slate-950 text-sm font-normal font-['Rubik']">Physical Address 2</div>
                                        <div className="text-slate-400 text-sm font-normal font-['Rubik']">{driver.personalInfo.addressb}</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="intro-y px-2 py-3 bg-gray-100 text-right mx-8 my-8">
                        <button type="submit" className="inline-flex justify-center py-2 px-4 mr-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700" onClick={() => { Reject() }}>
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "REJECT APPLICATION"}
                        </button>
                        <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700" onClick={() => { Accept() }}>
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "CONFIRM APPLICATION"}
                        </button>
                    </div>
                </Scrollbars>
            }
        </div>
    )
}
