import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { motion } from "framer-motion";
import { Scrollbars } from 'react-custom-scrollbars';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import { useAlert } from 'react-alert';
import Moment from 'moment';
import * as XLSX from 'xlsx';
import { Menu, Transition } from '@headlessui/react';

import { useRecoilState } from 'recoil';
import { tokenAtom, formAtom } from '../../../core/config/atoms';

import TopBar from '../../../core/widgets/ui/TopBar';

import ServiceApplication from '../../../services/serviceApplication';
import ServiceUtility from '../../../services/serviceUtility';

export default function PanelApplicationList(props) {

    const [token] = useRecoilState(tokenAtom);

    const [, setForm] = useRecoilState(formAtom);

    const [forms, setForms] = useState([]);

    const [filter, setFilter] = useState({});

    const [sdate, setSDate] = useState(Moment().startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isDate, setIsDate] = useState(false);

    const alert = useAlert();
    const navigate = useNavigate();
    const fileService = new ServiceUtility();
    const appService = new ServiceApplication();

    const changeTab = (val) => {
        setTab(val);
        setQuery('');
        setSDate(Moment().startOf('year').toDate());
        setEDate(Moment().toDate());
        switch (val) {
            case "all":
                setFilter({});
                break;
            case "draft":
                setFilter({ 'status': 'draft' });
                break;
            case "review":
                setFilter({ 'status': 'review' });
                break;
            case "cr":
                setFilter({ 'status': 'cr' });
                break;
            default:
                break;
        }
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const getExportItems = () => {
        let items = [];
        forms.forEach((item) => {
            items.push({
                appId: item.appId,
                appType: item.appType,
                applicantName: item.applicantName,
                applicantPhone: item.applicantPhone,
                status: item.status,
                createdAt: item.createdAt,
            });
        });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "forms");
        XLSX.writeFile(workbook, "forms.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['Application Id,Form Type,Applicant Name,Applicant Phone,status,createdAt'];
        let usersCsv = forms.reduce((acc, item) => {
            const { appId, appType, applicantName, applicantPhone, status, createdAt } = item
            acc.push([appId, appType, applicantName, applicantPhone, status, createdAt].join(','))
            return acc
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'forms.csv',
            fileType: 'text/csv',
        });
    }

    const onDateChange = (start, end) => {
        setIsDate(true);
        setSDate(start);
        setEDate(end);
    }

    const deleteForm = (formData) => {
        let promis = [];

        if (formData.data.guardianInfo) {
            if (formData.data.guardianInfo.gardPic) {
                let grdBody = { "path": formData.data.guardianInfo.gardPic.fileurl }
                promis.push(fileService.deleteFile(grdBody, token));
            }
        }

        if (formData.data.emrgencyInfo) {
            if (formData.data.emrgencyInfo.emAPic) {
                let emABody = { "path": formData.data.emrgencyInfo.emAPic.fileurl }
                promis.push(fileService.deleteFile(emABody, token));
            }

            if (formData.data.emrgencyInfo.emBPic) {
                let emBBody = { "path": formData.data.emrgencyInfo.emBPic.fileurl }
                promis.push(fileService.deleteFile(emBBody, token));
            }
        }

        if (formData.data.identityInfo) {
            let idA = { "path": formData.data.identityInfo.identityDoc.fileurl }
            promis.push(fileService.deleteFile(idA, token));

            let idB = { "path": formData.data.identityInfo.frontPic.fileurl }
            promis.push(fileService.deleteFile(idB, token));

            let idC = { "path": formData.data.identityInfo.smilePic.fileurl }
            promis.push(fileService.deleteFile(idC, token));

            let idD = { "path": formData.data.identityInfo.profilePic.fileurl }
            promis.push(fileService.deleteFile(idD, token));
        }

        if (formData.data.vehicleInfo) {
            let vheA = { "path": formData.data.vehicleInfo.frontPic.fileurl }
            promis.push(fileService.deleteFile(vheA, token));

            let vheB = { "path": formData.data.vehicleInfo.sidePic.fileurl }
            promis.push(fileService.deleteFile(vheB, token));

            if (formData.data.vehicleInfo.backPic) {
                let vheC = { "path": formData.data.vehicleInfo.backPic.fileurl }
                promis.push(fileService.deleteFile(vheC, token));
            }
        }

        Promise.all(promis).then((ress) => {
            let body = {
                fid: formData._id,
            }
            appService.deleteApplication(body, token).then((res) => {
                if (res['status']) {
                    loadData();
                    alert.show('Application deleted successfully', { type: 'success' });
                } else {
                    alert.show('Server error please try again', { type: 'error' });
                }
            });
        });
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await appService.getAllForms(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setForms(res.data.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await appService.getAllForms(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setForms(res.data.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    const addDriver = () => {
        setForm({});
        navigate(`/home/applicationAdd`);
    }

    const gotoForm = (data) => {
        switch (data.status) {
            case "draft":
                setForm(data);
                navigate(`/home/applicationAdd`);
                break;
            case "review":
                switch (data.appType) {
                    case "Form 1A":
                    case "Form 1B":
                        setForm(data);
                        navigate(`/home/applicationVerify`);
                        break;
                    case "Form 2A":
                        setForm(data);
                        navigate(`/home/driverVerify`);
                        break;
                    case "Form 3A":
                        setForm(data);
                        navigate(`/home/vehicleVerify`);
                        break;
                    default:
                        setForm({});
                        navigate(`/home/NA`);
                        break;
                }
                break;
            case "cr":
                switch (data.appType) {
                    case "Form CR1":
                        setForm(data);
                        navigate(`/home/CR1Verify`);
                        break;
                    case "Form CR2":
                        setForm(data);
                        navigate(`/home/CR2Verify`);
                        break;
                    case "Form CR3":
                        setForm(data);
                        navigate(`/home/CR3Verify`);
                        break;
                    case "Form CR4":
                        setForm(data);
                        navigate(`/home/CR4Verify`);
                        break;
                        break;
                    default:
                        setForm({});
                        navigate(`/home/NA`);
                        break;
                }
                break;
            default:
                break;
        }
    }

    const getDisc = (type) => {
        switch (type) {
            case "Form 1A":
                return "Application form to add driver information from dashboard.";
            case "Form 1B":
                return "Application form to add driver information from application.";
            case "Form CR1":
                return "Application form to update drivers personal information.";
            case "Form CR2":
                return "Application form to update drivers tutor information.";
            case "Form CR3":
                return "Application form to update drivers emrgency information.";
            case "Form CR4":
                return "Application form to update drivers identity information.";
            case "Form 2A":
                return "Application form to add new driver to vehicle.";
            case "Form 3A":
                return "Application form to approve new vehicle.";
            default:
                return "NA";
        }
    }

    const getChipColorA = (status) => {
        switch (status) {
            case "draft":
                return "bg-blue-100 text-blue-800";
            case "review":
                return "bg-green-100 text-green-800";
            case "cr":
                return "bg-yellow-100 text-yellow-800";
            default:
                return "bg-blue-100 text-blue-800";
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, sdate, edate, filter]);

    return (
        <div className="w-full h-full p-0 md:py-3 md:px-3">
            <Scrollbars>
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div className="intro-y flex items-center justify-between h-10">
                            <h2 className="text-xl md:text-2xl font-medium truncate ml-2">
                                <motion.i whileHover={{ scale: 1.2 }} className="las la-bars cursor-pointer" onClick={() => { props.onToggle() }}></motion.i> Application Master
                            </h2>
                            <div className='flex items-center'>
                                <div>
                                    <button className="py-1 px-2 md:py-2 md:px-3 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4" onClick={() => {
                                        addDriver();
                                    }}><i className="las la-plus mr-0 md:mr-2"></i> <span className='hidden md:inline'>Add Driver</span></button>
                                </div>
                                <TopBar />
                            </div>
                        </div>
                        <div className="w-full mt-2">
                            <div className="p-2 mt-2">
                                <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'all' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('all') }}>All</button></li>
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'draft' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('draft') }}>Draft</button></li>
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'review' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('review') }}>Review Request</button></li>
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'cr' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('cr') }}>Change Request</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 mt-4 md:px-2 w-full">
                    <div className="w-full shadow-md rounded-md border hidden md:block">
                        <table className="min-w-full divide-y divide-gray-200 px-4">
                            <thead className="bg-gray-50">
                                <tr className=''>
                                    <th scope="col" className="border-b border-gray-200" colSpan={7}>
                                        <div className='w-full grid grid-cols-12 gap-2'>
                                            <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                <div className='col-span-4'>
                                                    <RangeDatePicker
                                                        startDate={sdate}
                                                        endDate={edate}
                                                        minDate={new Date(2022, 8, 1)}
                                                        maxDate={Moment().toDate()}
                                                        disabled={false}
                                                        onCloseCalendar={(e) => {
                                                            loadData();
                                                        }}
                                                        onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                        className=""
                                                        startWeekDay="monday"
                                                    />
                                                </div>
                                                <div className='w-full flex col-span-8 items-center'>
                                                    <div className='w-[80px]'>
                                                        <p>Search :</p>
                                                    </div>
                                                    <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by name and phone." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                </div>
                                            </form>
                                            <div className='col-span-1 flex items-center'>
                                                <Menu>
                                                    <Menu.Button>
                                                        <div className="w-full flex items-center justify-center py-1 px-2 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4">
                                                            <i className="las la-file-download mr-2"></i> Export
                                                        </div>
                                                    </Menu.Button>
                                                    <Transition
                                                        enter="transition duration-100 ease-out"
                                                        enterFrom="transform scale-95 opacity-0"
                                                        enterTo="transform scale-100 opacity-100"
                                                        leave="transition duration-75 ease-out"
                                                        leaveFrom="transform scale-100 opacity-100"
                                                        leaveTo="transform scale-95 opacity-0">
                                                        <Menu.Items>
                                                            <div
                                                                className='bg-white shadow-md rounded-md absolute' style={{ top: '32px', left: '-176px' }}>
                                                                <div>
                                                                    <ul className='flex flex-col items-start p-2'>
                                                                        <Menu.Item>
                                                                            <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                exportToExcel();
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                                </div>
                                                                            </li>
                                                                        </Menu.Item>
                                                                        <Menu.Item>
                                                                            <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                exportToCsv();
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                                </div>
                                                                            </li>
                                                                        </Menu.Item>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Application ID
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Applicant Details
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Application Type
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Progress
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Created At
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Status
                                    </th>
                                    <th scope="col" className="px-2 py-3"></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    forms.length !== 0 && forms.map((form) => {
                                        return <tr className="hover:bg-gray-100" key={form._id}>
                                            <td className="px-6 py-4 whitespace-nowrap cursor-pointer" onClick={() => {
                                                gotoForm(form);
                                            }}>
                                                <div className="text-sm font-bold text-gray-900">
                                                    <div className='flex items-center text-blue-600'>
                                                        {form.appId}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div>
                                                    <div className="text-sm font-medium text-gray-900">
                                                        {form.applicantName}
                                                    </div>
                                                    <div className="text-sm text-gray-500">
                                                        Phone: +243 {form.applicantPhone}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div>
                                                    <div className="text-sm font-medium text-gray-900">
                                                        {form.appType}
                                                    </div>
                                                    <div className="text-sm text-gray-500">
                                                        {getDisc(form.appType)}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <p className='text-xs font-medium text-grey-800'>Progress: {form.progress.toFixed(2)} %</p>
                                                <div className='w-[200px] bg-gray-200 rounded-full mt-2'>
                                                    <div style={{ width: `${form.progress}%` }} className="bg-blue-600 h-[14px] rounded-full flex items-center justify-center"></div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-blue-600">
                                                    {Moment(form.createdAt).format('DD, MMM yyyy')}
                                                </div>
                                            </td>
                                            <td className='flex mt-4'>
                                                <p className='font-sans font-medium'>
                                                    <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(form.status)}`}>
                                                        {form.status}
                                                    </span>
                                                </p>
                                            </td>
                                            <td>
                                                {
                                                    (form.appType === "Form 1A" && form.status === "draft") && <Menu>
                                                        <Menu.Button>
                                                            <motion.i whileHover={{ scale: 1.1 }} className="las la-ellipsis-v text-lg"></motion.i>
                                                        </Menu.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0">
                                                            <Menu.Items>
                                                                <div
                                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-142px' }}>
                                                                    <div>
                                                                        <ul className='flex flex-col items-start p-2'>
                                                                            <Menu.Item>
                                                                                <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                    deleteForm(form);
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <i className="las la-trash text-lg"></i>
                                                                                        <p className='text-sm font-serif ml-2'>Delete</p>
                                                                                    </div>
                                                                                </li>
                                                                            </Menu.Item>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                }
                                                {
                                                    (form.appType !== "Form 1A" || form.status !== "draft") && <>-</>
                                                }
                                            </td>
                                        </tr>
                                    })
                                }
                                {
                                    forms.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                        <td className="px-6 py-4 whitespace-nowrap" colSpan="7">
                                            <div className="text-sm text-blue-600 text-center">
                                                No data found.
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <div className='flex py-2 px-4 items-center bg-blue-50'>
                            <p className='text-sm font-serif'>Show rows per page</p>
                            <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                            </select>
                            <div className='flex-grow'></div>
                            <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                            <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                            <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                        </div>
                    </div>
                    <div className="w-full md:hidden">
                        <div className='pl-[4%] mb-4 w-[92%] flex my-2 items-center'>
                            <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by name and phone." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                        </div>
                        {
                            forms.length !== 0 && forms.map((form) => {
                                return <div className="w-[88%] ml-[4%] overflow-hidden shadow-md rounded-md border" key={form._id}>
                                    <div className="px-6 py-2 whitespace-nowrap cursor-pointer flex items-center justify-between">
                                        <div className="text-sm font-bold text-gray-900" onClick={() => {
                                            gotoForm(form);
                                        }}>
                                            <div className='flex items-center text-blue-600'>
                                                {form.appId}
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                (form.appType === "Form 1A" && form.status === "draft") && <Menu>
                                                    <Menu.Button>
                                                        <motion.i whileHover={{ scale: 1.1 }} className="las la-ellipsis-v text-lg"></motion.i>
                                                    </Menu.Button>
                                                    <Transition
                                                        enter="transition duration-100 ease-out"
                                                        enterFrom="transform scale-95 opacity-0"
                                                        enterTo="transform scale-100 opacity-100"
                                                        leave="transition duration-75 ease-out"
                                                        leaveFrom="transform scale-100 opacity-100"
                                                        leaveTo="transform scale-95 opacity-0">
                                                        <Menu.Items>
                                                            <div
                                                                className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-142px' }}>
                                                                <div>
                                                                    <ul className='flex flex-col items-start p-2'>
                                                                        <Menu.Item>
                                                                            <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                deleteForm(form);
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <i className="las la-trash text-lg"></i>
                                                                                    <p className='text-sm font-serif ml-2'>Delete</p>
                                                                                </div>
                                                                            </li>
                                                                        </Menu.Item>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            }
                                            {
                                                (form.appType !== "Form 1A" || form.status !== "draft") && <>-</>
                                            }
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="px-6 py-2 whitespace-nowrap">
                                        <div>
                                            <div className="text-sm font-medium text-gray-900">
                                                {form.applicantName}
                                            </div>
                                            <div className="text-sm text-gray-500">
                                                Phone: +243 {form.applicantPhone}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-6 py-2 whitespace-nowrap">
                                        <div>
                                            <div className="text-sm font-medium text-gray-900">
                                                {form.appType}
                                            </div>
                                            <p className="text-sm text-gray-500 w-full">
                                                {getDisc(form.appType)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="px-6 py-2 whitespace-nowrap">
                                        <p className='text-xs font-medium text-grey-800'>Progress: {form.progress.toFixed(2)} %</p>
                                        <div className='w-[200px] bg-gray-200 rounded-full mt-2'>
                                            <div style={{ width: `${form.progress}%` }} className="bg-blue-600 h-[14px] rounded-full flex items-center justify-center"></div>
                                        </div>
                                    </div>
                                    <hr className='my-2' />
                                    <div className="px-6 mb-4 whitespace-nowrap flex items-center justify-between">
                                        <div className="text-sm text-blue-600">
                                            {Moment(form.createdAt).format('DD, MMM yyyy')}
                                        </div>
                                        <p className='font-sans font-medium'>
                                            <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(form.status)}`}>
                                                {form.status}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            })
                        }
                        {
                            forms.length === 0 && <div className="cursor-pointer bg-gray-100 w-full">
                                <div className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-blue-600 text-center">
                                        No data found.
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='flex py-2 px-4 items-center bg-blue-50 mt-8'>
                            <p className='text-sm font-serif'>Show rows per page</p>
                            <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                            </select>
                            <div className='flex-grow'></div>
                            <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                            <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                            <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div >
    )
}
