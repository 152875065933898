import React, { useState, useEffect } from 'react';

import Lottie from 'lottie-react';
import { useAlert } from 'react-alert';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../core/config/atoms';

import animPage from '../../../assets/anim/anim-pageload.json';

import ServiceCustomer from '../../../services/serviceCustomer';
import moment from 'moment';
import PanelDev from '../../dev/PanelDev';

export default function ComponentCustomerInfo({ cid }) {

    const [token] = useRecoilState(tokenAtom);

    const [user, setUser] = useState({});

    const [loading, setLoading] = useState(true);

    const [tab, setTab] = useState('ride');

    const alert = useAlert();
    const customerService = new ServiceCustomer();

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                "cid": cid,
            };
            var res = await customerService.getCustomerById(body, token);
            if (res['status']) {
                setUser(res.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, [cid]);

    return (
        <div>
            {
                loading && <div className='flex items-center justify-center w-full h-screen'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            {
                !loading && <div>
                    <div className='h-[320px] bg-blue-50 px-6 relative'>
                        <div className='h-[220px] bg-blue-600 rounded-b-md'></div>
                        <div className='w-[180px] h-[180px] bg-white border-[6px] border-blue-50 rounded-full absolute top-[120px] ml-8'>
                            {
                                (user['profilePic'] && user['profilePic']['fileurl'] !== "") ? <img src={user['profilePic']['fileurl']} alt='Profile Pic' className='w-full h-full rounded-full' /> : <div className='w-full h-full rounded-full bg-gray-100 flex items-center justify-center'>
                                    <h1 className='uppercase font-medium'>{user['fname'][0]}{user['lname'][0]}</h1>
                                </div>
                            }
                        </div>
                        <h1 className='ml-[220px] text-2xl font-medium'>{user['fname']} {user['mname']} {user['lname']}</h1>
                    </div>
                    <div className='bg-gray-50 px-16 py-4'>
                        <div className='bg-white w-full shadow rounded-md p-4 flex'>
                            <div className='w-[30%]'>
                                <h2 className='text-xl font-medium'>About</h2>
                                <ul className='mt-2'>
                                    <li className='text-sm font-medium py-1 px-2 rounded-md text-blue-600 bg-blue-50 hover:bg-blue-50'>Personal Information</li>
                                </ul>
                            </div>
                            <div className='w-[1px] bg-gray-200 mx-2'></div>
                            <div className='flex-grow'>
                                <div className='p-2'>
                                    <div className="text-slate-400 text-xs font-normal">Email Address</div>
                                    <div className="text-slate-950 text-sm font-medium">{user['email'] === "" ? "NA" : user['email']}</div>
                                </div>
                                <div className='p-2'>
                                    <div className="text-slate-400 text-xs font-normal">Phone Number</div>
                                    <div className="text-slate-950 text-sm font-medium">+243 {user['phone']}</div>
                                </div>
                                <div className='p-2'>
                                    <div className="text-slate-400 text-xs font-normal">Date of Birth</div>
                                    <div className="text-slate-950 text-sm font-medium">{moment(user['dob']).format('DD MMM yyyy')}</div>
                                </div>
                                <div className='p-2'>
                                    <div className="text-slate-400 text-xs font-normal">Physical Address</div>
                                    <div className="text-slate-950 text-sm font-medium">{user['addresses'][0]['address']}</div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white w-full shadow rounded-md p-4 mt-4'>
                            <h2 className='text-xl font-medium'>Activity</h2>
                            <div class="w-full mt-2">
                                <div className="py-2 mt-2">
                                    <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                        <li className="mx-2"><button className={`py-2 px-2 ${tab === 'ride' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('ride') }}>Rides Report</button></li>
                                        <li className="mx-2"><button className={`py-2 px-2 ${tab === 'ridereq' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('ridereq') }}>Ride Requests</button></li>
                                        <li className="mx-2"><button className={`py-2 px-2 ${tab === 'verification' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('verification') }}>Verification Report</button></li>
                                    </ul>
                                </div>
                            </div>
                            {
                                (() => {
                                    switch (tab) {
                                        // case 'ride':
                                        //     return (<PanelReportRide />);
                                        // case 'ridereq':
                                        //     return (<PanelReportRideReq />);
                                        // case 'verification':
                                        //     return (<PanelReportVerification />);
                                        default:
                                            return (<PanelDev />);
                                    }
                                })()
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
