import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom, userAtom } from '../../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import Lottie from 'lottie-react';

import ServiceApplication from '../../../../services/serviceApplication';

import animBtnLoading from '../../../../assets/anim/anim-btnLoading.json';

export default function ComponentStepG(props) {

    const [token] = useRecoilState(tokenAtom);
    const [user] = useRecoilState(userAtom);

    const [submit, setSubmit] = useState(false);

    const alert = useAlert();
    const appService = new ServiceApplication();

    const formVSchema = Yup.object().shape({
        name: Yup.string().required('This information is required'),
        place: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            tick: false,
            name: '',
            place: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            if (values.tick) {
                let body = {
                    "fid": props.data._id,
                    "opration": "updateStepG",
                    "data": {
                        signId: user._id,
                        signName: values.name,
                        signPlace: values.place,
                    }
                }
                appService.updateFormA(body, token).then((res) => {
                    setSubmit(false);
                    if (res.status) {
                        alert.show("Application submitted!", { type: 'success' });
                        props.gotoNext(res.data);
                    } else {
                        alert.show("Server error, please try again!", { type: 'error' });
                    }
                });
            } else {
                setSubmit(false);
                alert.show("Please read and accept terms and try again!", { type: 'error' });
            }
        },
    });

    return (
        <form onSubmit={handleSubmit}>
            <div className="mt-4 grid grid-cols-12 gap-6">
                <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                    <div className="shadow">
                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6">
                                    <div className='flex items-start'>
                                        <input type="checkbox" id="tick" checked={values.tick} value={values.tick} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-4 h-4 shadow-inner bg-gray-100 text-sm border-gray-400 outline-none" />
                                        <label className="text-sm font-medium text-gray-700 flex flex-col ml-4">
                                            <span>I agree to all the terms and conditions of AfriSafe verifiy my identity and declare that all the information provided by me is true and verified by me</span>
                                            <span className='text-blue-600'><a href='https://afrisafe.taxi/en/terms' target='_blank'>Terms & Conditions</a></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Full Name<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="name" value={values.name} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. Jhon Deo" />
                                    {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                                </div>
                                <div className="col-span-2"></div>
                                <div className="col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Place<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="place" value={values.place} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. Kinshsa" />
                                    {(errors.place && touched.place) && <p className='text-xs text-red-400 mt-1'>{errors.place}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-y hidden sm:block">
                <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                </div>
            </div>
            <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700">
                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                    {!submit && "SUBMIT FORM 1A"}
                </button>
            </div>
        </form>
    )
}
