import React from 'react';
import { useParams } from 'react-router-dom';

import PanelCustomerList from '../panels/customer/PanelCustomerList';
import PanelCustomerInfo from '../panels/customer/PanelCustomerInfo';

import PanelDev from '../panels/dev/PanelDev';

export default function LayoutCustomer() {

    const { page } = useParams();

    return (
        <div className='h-screen w-full'>
            {
                (() => {
                    switch (page) {
                        case 'customer':
                            return (<PanelCustomerList />);
                        case 'customerInfo':
                            return (<PanelCustomerInfo />);
                        default:
                            return (<PanelDev />);
                    }
                })()
            }
        </div>
    )
}
