import React, { useState, useRef, useEffect } from 'react';

import { useTranslation } from "react-i18next";

import { useRecoilState } from 'recoil';
import { authAtom, userAtom, userRoleAtom, tokenAtom } from '../core/config/atoms';

import Lottie from 'lottie-react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

import logo from '../assets/image/logo.png';
import loginBg from '../assets/image/bg-login.jpg';
import animBtnLoading from '../assets/anim/anim-btnLoading.json';

import ServiceStaff from '../services/serviceStaff';
import ServiceUtility from '../services/serviceUtility';

export default function PageAuth() {
    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [userData, setUserData] = useRecoilState(userAtom);
    const [, setRoleData] = useRecoilState(userRoleAtom);
    const [, setToken] = useRecoilState(tokenAtom);

    const { t } = useTranslation();

    const [submit, setSubmit] = useState(false);

    const emailRef = useRef('');
    const passwordRef = useRef('');

    const alert = useAlert();
    const navigate = useNavigate();

    const staffService = new ServiceStaff();
    const utilService = new ServiceUtility();

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmit(true);

        const email = emailRef.current.value;
        const password = passwordRef.current.value;

        if (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
            if (password === "" || password === undefined) {
                alert.show(t('auth6'), { type: 'error' });
                setSubmit(false);
            } else {
                var body = {
                    email: email,
                    password: password
                };
                staffService.loginStaff(body, '').then((res) => {
                    if (res.status) {
                        setUserData(res.data);
                        setRoleData(res.data.role);
                        utilService.getTokenById({ id: res.data._id }, '').then((d) => {
                            setToken(d.data.token);
                            setAuthStatus('verified');
                            navigate('/home/overview');
                        });
                    } else {
                        alert.show('Error: ' + res.msg, { type: 'error' });
                    }
                    setSubmit(false);
                });
            }
        } else {
            alert.show(t('auth7'), { type: 'error' });
            setSubmit(false);
        }
    }

    useEffect(() => {
        if (authStatus === 'verified') {
            if (userData.role === 'MANEGER') {
                navigate('/home/applications');
            } else {
                navigate('/home/overview');
            }
        }
    }, [authStatus, navigate]);

    return (
        <>
            <div className="w-screen h-screen overflow-hidden relative">
                <div className="xl:grid xl:grid-cols-12 xl:gap-0">
                    <div className="absolute xl:relative w-full xl:col-span-5">
                        <div className="h-screen flex flex-col items-center xl:bg-blue-50">
                            <img src={logo} alt="brand logo" className="mt-20 w-40" />
                            <div className="shadow-lg rounded-xl px-4 xl:px-10 py-20 xl:py-10 bg-white mt-6 mx-2 max-w-md md:w-auth w-11/12">
                                <h1 className="text-2xl text-black font-serif font-medium">{t('auth1')}</h1>
                                <h2 className="text-sm text-gray-400 font-serif">{t('auth2')}</h2>
                                <form className="mt-8" onSubmit={handleSubmit}>
                                    <div className="flex flex-col">
                                        <label className="text-sm text-gray-900 font-serif">{t('auth3')}
                                            <sup className="text-red-600">*</sup>
                                        </label>
                                        <input type="email" placeholder="i.e. jhon.deo@gmail.com" className="w-full h-11 rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1" required autoComplete="off" ref={emailRef} />
                                    </div>
                                    <div className="flex flex-col mt-4">
                                        <label className="text-sm text-gray-900 font-serif">{t('auth4')}
                                            <sup className="text-red-600">*</sup>
                                        </label>
                                        <input type="password" placeholder="i.e. xxxxxxxx" className="w-full h-11 rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1" required autoComplete="off" ref={passwordRef} />
                                    </div>
                                    <button type="submit" className="mt-8 text-xs font-bold text-white rounded bg-sky-600 hover:bg-sky-700 w-full h-10 font-mono flex items-center justify-center" disabled={submit}>
                                        {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" />}
                                        {!submit && t('auth5')}
                                    </button>
                                </form>
                                <div id="captcha"></div>
                                <div className="flex-grow"></div>
                                <p className="text-sm text-gray-400 font-serif text-center mt-16">&copy; 2022 - 2023 AfriDisk PVT LTD.</p>
                            </div>
                        </div>
                    </div>
                    <div className="h-screen xl:col-span-7 xl:flex items-center">
                        <img className="w-full h-full object-cover xl:object-fill" src={loginBg} alt="Login page background" />
                    </div>
                </div>
            </div>
        </>
    )
}