import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceApplication {
    lookupService = new APIServiceLookUp();

    async getAllForms(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}application/getAllForms`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async createForm1A(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}application/createForm1A`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateFormA(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}application/updateFormA`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deleteApplication(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}application/deleteApplication`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async acceptForm1A(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}application/acceptForm1A`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async acceptForm1B(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}application/acceptForm1B`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async acceptForm2A(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}application/acceptForm2A`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async acceptForm3A(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}application/acceptForm3A`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async acceptFormCR1(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}application/acceptFormCR1`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async acceptFormCR2(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}application/acceptFormCR2`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async acceptFormCR3(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}application/acceptFormCR3`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async acceptFormCR4(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}application/acceptFormCR4`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}