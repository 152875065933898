import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Lottie from 'lottie-react';

import animSad from '../assets/anim/anim-sad.json';

export default function Page404() {

    const navigate = useNavigate();

    const { t } = useTranslation();

    const handleClick = () => {
        navigate('/', { replace: true });
    }

    return (
        <>
            <div className="w-screen h-screen overflow-hidden flex items-center justify-center">
                <div className="container">
                    <Lottie animationData={animSad} className="intro-y w-24 mx-auto mb-20" loop={true} />
                    <h2 className="intro-y text-red-600 text-center lg:text-big text-9xl font-serif font-bold">404</h2>
                    <p className=" intro-y text-gray-900 lg:text-lg text-base text-center font-serif font-normal w-4/5 mx-auto">{t('errM1')}<br />{t('errM2')}</p>
                    <div className="intro-y text-white lg:text-xl text-base text-center font-serif bg-red-500 hover:bg-red-600 cursor-pointer rounded-md lg:w-80 w-60 mx-auto mt-20 py-2" onClick={handleClick}>{t('errM3')}</div>
                </div>
            </div>
        </>
    )
}
