import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import Lottie from 'lottie-react';

import ServiceApplication from '../../../../services/serviceApplication';
import ServiceUtility from '../../../../services/serviceUtility';
import ServiceVehicle from '../../../../services/serviceVehicle';

import animBtnLoading from '../../../../assets/anim/anim-btnLoading.json';

const allowedExtensions = ["png", "jpeg"];

export default function ComponentStepE(props) {

    const [token] = useRecoilState(tokenAtom);

    const [files, setFiles] = useState([null, null, null]);
    const [previews, setPreviews] = useState([null, null, null]);

    const [submit, setSubmit] = useState(false);

    const alert = useAlert();
    const fileService = new ServiceUtility();
    const appService = new ServiceApplication();
    const vehicleService = new ServiceVehicle();

    const formVSchema = Yup.object().shape({
        vheTType: Yup.string().required('This information is required'),
        vheManufacturer: Yup.string().required('This information is required'),
        vheModel: Yup.string().required('This information is required'),
        vheType: Yup.string().required('This information is required'),
        vheColor: Yup.string().required('This information is required'),
        vheChNumer: Yup.string().required('This information is required'),
        vheRegNumer: Yup.string().required('This information is required'),
        vheServiceNumer: Yup.string().required('This information is required'),
        vheSeat: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            vheTType: '',
            vheManufacturer: '',
            vheModel: '',
            vheType: '',
            vheColor: '',
            vheChNumer: '',
            vheRegNumer: '',
            vheServiceNumer: '',
            vheSeat: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            if (files[0] !== null && files[1] !== null) {
                vehicleService.checkVehicle({ "chNumber": values.vheChNumer, "regNumber": values.vheRegNumer }, token).then((res) => {
                    if (res.status) {
                        let promis = [];

                        var formDataA = new FormData();
                        if (props.data.data.vehicleInfo) {
                            formDataA.append("path", props.data.data.vehicleInfo.frontPic.fileurl);
                            formDataA.append("doc", files[0]);
                            promis.push(fileService.replaceFile(formDataA, token));
                        } else {
                            formDataA.append("path", 'vehicle');
                            formDataA.append("doc", files[0]);
                            promis.push(fileService.uploadFile(formDataA, token));
                        }

                        var formDataB = new FormData();
                        if (props.data.data.vehicleInfo) {
                            formDataB.append("path", props.data.data.vehicleInfo.sidePic.fileurl);
                            formDataB.append("doc", files[1]);
                            promis.push(fileService.replaceFile(formDataB, token));
                        } else {
                            formDataB.append("path", 'vehicle');
                            formDataB.append("doc", files[1]);
                            promis.push(fileService.uploadFile(formDataB, token));
                        }

                        if (files[2] !== null) {
                            var formDataC = new FormData();
                            if (props.data.data.vehicleInfo) {
                                if (props.data.data.vehicleInfo.backPic) {
                                    formDataC.append("path", props.data.data.vehicleInfo.backPic.fileurl);
                                    formDataC.append("doc", files[2]);
                                    promis.push(fileService.replaceFile(formDataC, token));
                                } else {
                                    formDataC.append("path", 'user');
                                    formDataC.append("doc", files[2]);
                                    promis.push(fileService.uploadFile(formDataC, token));
                                }
                            } else {
                                formDataC.append("path", 'vehicle');
                                formDataC.append("doc", files[2]);
                                promis.push(fileService.uploadFile(formDataC, token));
                            }
                        } else {
                            promis.push(new Promise(
                                resolve => setTimeout(resolve, 400)
                            ));
                        }

                        Promise.all(promis).then((ress) => {
                            let body = {
                                "fid": props.data._id,
                                "opration": "updateStepE",
                                "data": {
                                    vheTType: values.vheTType,
                                    vheManufacturer: values.vheManufacturer,
                                    vheModel: values.vheModel,
                                    vheType: values.vheType,
                                    vheColor: values.vheColor,
                                    vheChNumer: values.vheChNumer,
                                    vheRegNumer: values.vheRegNumer,
                                    vheServiceNumer: values.vheServiceNumer,
                                    vheSeat: values.vheSeat,
                                    frontPic: {},
                                    sidePic: {},
                                    backPic: {},
                                }
                            }

                            if (files[0] !== null) {
                                if (ress[0].status) {
                                    body["data"]["frontPic"] = ress[0].data;
                                } else {
                                    alert.show("File upload error, please try again!", { type: 'error' });
                                }
                            } else if (getIsDoc('A')) {
                                body["data"]["frontPic"] = props.data.data.vehicleInfo.frontPic;
                            }

                            if (files[1] !== null) {
                                if (ress[1].status) {
                                    body["data"]["sidePic"] = ress[1].data;
                                } else {
                                    alert.show("File upload error, please try again!", { type: 'error' });
                                }
                            } else if (getIsDoc('B')) {
                                body["data"]["sidePic"] = props.data.data.vehicleInfo.sidePic;
                            }

                            if (files[2] !== null) {
                                if (ress[2].status) {
                                    body["data"]["backPic"] = ress[2].data;
                                } else {
                                    alert.show("File upload error, please try again!", { type: 'error' });
                                }
                            } else if (getIsDoc('B')) {
                                body["data"]["backPic"] = props.data.data.vehicleInfo.backPic;
                            }

                            appService.updateFormA(body, token).then((res) => {
                                setSubmit(false);
                                if (res.status) {
                                    alert.show("Application updated!", { type: 'success' });
                                    props.gotoNext(6, res.data);
                                } else {
                                    alert.show("Server error, please try again!", { type: 'error' });
                                }
                            });
                        });
                    } else {
                        setSubmit(false);
                        alert.show("This vehicle already exists in the system please verify details try again!", { type: 'error' });
                    }
                });
            } else {
                setSubmit(false);
                alert.show("Please select all the required files and try again!", { type: 'error' });
            }
        },
    });

    const handleFileChange = (e, index) => {
        var { value, name } = e.target;
        let isError = false;

        const inputFile = e.target.files[0];
        const fileExtension = inputFile?.type.split("/")[1];

        if (!allowedExtensions.includes(fileExtension)) {
            alert.show("Only .png file format is allowed, please try again!", { type: 'error' });
            isError = true;
        }

        if (isError) {
            return;
        }
        const newFiles = [...files];
        newFiles[index] = inputFile;
        setFiles(newFiles);

        const newPreviews = [...previews];
        const reader = new FileReader();
        reader.onload = () => {
            newPreviews[index] = reader.result;
            setPreviews(newPreviews);
        };
        reader.readAsDataURL(inputFile);
    };

    const getIsDoc = (cheack) => {
        if (props.data.data.vehicleInfo) {
            switch (cheack) {
                case 'A':
                    return props.data.data.vehicleInfo.frontPic;
                case 'B':
                    return props.data.data.vehicleInfo.sidePic;
                case 'C':
                    return props.data.data.vehicleInfo.backPic;
            }
        } else {
            return false;
        }
    }

    const skip = () => {
        setSubmit(true);
        if (props.data.data.vehicleInfo) {
            let promis = [];
            let bodyA = { "path": props.data.data.vehicleInfo.frontPic.fileurl }
            promis.push(fileService.deleteFile(bodyA, token));

            let bodyB = { "path": props.data.data.vehicleInfo.sidePic.fileurl }
            promis.push(fileService.deleteFile(bodyB, token));

            if (props.data.data.vehicleInfo.backPic) {
                let bodyC = { "path": props.data.data.vehicleInfo.backPic.fileurl }
                promis.push(fileService.deleteFile(bodyC, token));
            }

            Promise.all(promis).then((ress) => {
                let body = {
                    "fid": props.data._id,
                    "opration": "updateStepE",
                    "data": {}
                }
                appService.updateFormA(body, token).then((res) => {
                    setSubmit(false);
                    if (res.status) {
                        alert.show("Application updated!", { type: 'success' });
                        props.gotoNext(6, res.data);
                    } else {
                        alert.show("Server error, please try again!", { type: 'error' });
                    }
                });
            });
        } else {
            let body = {
                "fid": props.data._id,
                "opration": "updateStepE",
                "data": {}
            }
            appService.updateFormA(body, token).then((res) => {
                setSubmit(false);
                if (res.status) {
                    alert.show("Application updated!", { type: 'success' });
                    props.gotoNext(6, res.data);
                } else {
                    alert.show("Server error, please try again!", { type: 'error' });
                }
            });
        }
    }

    useEffect(() => {
        if (props.data.data.vehicleInfo) {
            setValues({ ...values, ...props.data.data.vehicleInfo });
        }
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <div className="mt-4 grid grid-cols-12 gap-6">
                <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                    <div className="shadow">
                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Vehicle Information</h3>
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Vehicle Taxi Type<sup className="text-red-600">*</sup></label>
                                    <select className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" id="vheTType" value={values.vheTType} onChange={handleChange}>
                                        <option value="">Select Taxi Type</option>
                                        <option value="1">4 Wheel</option>
                                        <option value="2">2 Wheel</option>
                                    </select>
                                    {(errors.vheTType && touched.vheTType) && <p className='text-xs text-red-400 mt-1'>{errors.vheTType}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Vehicle Manufacturer<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="vheManufacturer" value={values.vheManufacturer} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. Toyota, Nissan, Ford" />
                                    {(errors.vheManufacturer && touched.vheManufacturer) && <p className='text-xs text-red-400 mt-1'>{errors.vheManufacturer}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Vehicale Model<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="vheModel" value={values.vheModel} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. IST, Xterra, F150" />
                                    {(errors.vheModel && touched.vheModel) && <p className='text-xs text-red-400 mt-1'>{errors.vheModel}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Type of Vehicle<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="vheType" value={values.vheType} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. SUV, Sedan, Van" />
                                    {(errors.vheType && touched.vheType) && <p className='text-xs text-red-400 mt-1'>{errors.vheType}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Color<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="vheColor" value={values.vheColor} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. White, Black, Red" />
                                    {(errors.vheColor && touched.vheColor) && <p className='text-xs text-red-400 mt-1'>{errors.vheColor}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Chassis number<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="vheChNumer" value={values.vheChNumer} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 12346549" />
                                    {(errors.vheChNumer && touched.vheChNumer) && <p className='text-xs text-red-400 mt-1'>{errors.vheChNumer}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Registration number (Plate)<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="vheRegNumer" value={values.vheRegNumer} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. XX-XX-XXXX" />
                                    {(errors.vheRegNumer && touched.vheRegNumer) && <p className='text-xs text-red-400 mt-1'>{errors.vheRegNumer}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Taxi service registration number<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="vheServiceNumer" value={values.vheServiceNumer} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 1234579" />
                                    {(errors.vheServiceNumer && touched.vheServiceNumer) && <p className='text-xs text-red-400 mt-1'>{errors.vheServiceNumer}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Number of seats<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="vheSeat" value={values.vheSeat} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 3" />
                                    {(errors.vheSeat && touched.vheSeat) && <p className='text-xs text-red-400 mt-1'>{errors.vheSeat}</p>}
                                </div>

                                <div className="col-span-6"></div>

                                <div className="col-span-2">
                                    <div className="py-2 bg-white">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Photo of Vehicle Font View <span className='text-xs text-gray-400'><sup className="text-red-600">*</sup></span>
                                            </label>
                                            {
                                                (files[0] === null && !getIsDoc('A')) && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                    <div className="space-y-1 text-center">
                                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <div className="flex text-sm text-gray-600">
                                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                <span>Upload a file</span>
                                                                <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 0) }} />
                                                            </label>
                                                            <p className="pl-1">or drag and drop</p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">
                                                            PNG, JPG, PDF up to 1MB
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                files[0] !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                    <div className="w-full flex items-center justify-center py-4">
                                                        <img src={previews[0]} className='w-[400px] h-[400px]' />
                                                    </div>
                                                    <hr />
                                                    <div className="flex justify-between mt-2">
                                                        <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                            <span>View</span>
                                                        </label>
                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                            <span>Replace</span>
                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 0) }} />
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (files[0] === null && getIsDoc('A')) && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                    <div className="w-full flex items-center justify-center py-4">
                                                        <img src={props.data.data.vehicleInfo.frontPic.fileurl} className='w-[400px] h-[400px]' />
                                                    </div>
                                                    <hr />
                                                    <div className="flex justify-between mt-2">
                                                        <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                            <span>View</span>
                                                        </label>
                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                            <span>Replace</span>
                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 0) }} />
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-2">
                                    <div className="py-2 bg-white">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Photo of Vehicle Side View <span className='text-xs text-gray-400'><sup className="text-red-600">*</sup></span>
                                            </label>
                                            {
                                                (files[1] === null && !getIsDoc('B')) && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                    <div className="space-y-1 text-center">
                                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <div className="flex text-sm text-gray-600">
                                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                <span>Upload a file</span>
                                                                <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 1) }} />
                                                            </label>
                                                            <p className="pl-1">or drag and drop</p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">
                                                            PNG, JPG, PDF up to 1MB
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                files[1] !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                    <div className="w-full flex items-center justify-center py-4">
                                                        <img src={previews[1]} className='w-[400px] h-[400px]' />
                                                    </div>
                                                    <hr />
                                                    <div className="flex justify-between mt-2">
                                                        <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                            <span>View</span>
                                                        </label>
                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                            <span>Replace</span>
                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 1) }} />
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (files[1] === null && getIsDoc('B')) && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                    <div className="w-full flex items-center justify-center py-4">
                                                        <img src={props.data.data.vehicleInfo.sidePic.fileurl} className='w-[400px] h-[400px]' />
                                                    </div>
                                                    <hr />
                                                    <div className="flex justify-between mt-2">
                                                        <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                            <span>View</span>
                                                        </label>
                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                            <span>Replace</span>
                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 1) }} />
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-2">
                                    <div className="py-2 bg-white">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Photo of Vehicle Back View <span className='text-xs text-gray-400'>(optional)</span>
                                            </label>
                                            {
                                                (files[2] === null && !getIsDoc('C')) && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                    <div className="space-y-1 text-center">
                                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <div className="flex text-sm text-gray-600">
                                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                <span>Upload a file</span>
                                                                <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 2) }} />
                                                            </label>
                                                            <p className="pl-1">or drag and drop</p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">
                                                            PNG, JPG, PDF up to 1MB
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                files[2] !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                    <div className="w-full flex items-center justify-center py-4">
                                                        <img src={previews[2]} className='w-[400px] h-[400px]' />
                                                    </div>
                                                    <hr />
                                                    <div className="flex justify-between mt-2">
                                                        <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                            <span>View</span>
                                                        </label>
                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                            <span>Replace</span>
                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 2) }} />
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (files[2] === null && getIsDoc('C')) && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                    <div className="w-full flex items-center justify-center py-4">
                                                        <img src={props.data.data.vehicleInfo.backPic.fileurl} className='w-[400px] h-[400px]' />
                                                    </div>
                                                    <hr />
                                                    <div className="flex justify-between mt-2">
                                                        <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                            <span>View</span>
                                                        </label>
                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                            <span>Replace</span>
                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 2) }} />
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-y hidden sm:block">
                <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                </div>
            </div>
            <div className="intro-y px-2 py-3 bg-gray-100 flex items-center justify-end">
                <div className='mr-4 font-medium text-sky-600 cursor-pointer' onClick={() => { skip() }}>
                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                    {!submit && "Skip for now"}
                </div>
                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700" >
                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                    {!submit && "SAVE & NEXT"}
                </button>
            </div>
        </form>
    )
}
