import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { motion } from "framer-motion";
import { Scrollbars } from 'react-custom-scrollbars';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import { useAlert } from 'react-alert';
import Moment from 'moment';
import * as XLSX from 'xlsx';
import { Menu, Transition } from '@headlessui/react';
import { Wrapper } from "@googlemaps/react-wrapper";
import { GoogleMap, Marker } from '@react-google-maps/api';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../core/config/atoms';

import TopBar from '../../../core/widgets/ui/TopBar';

import ServiceDriver from '../../../services/serviceDriver';
import moment from 'moment';

export default function PanelDriverList(props) {
    const [token] = useRecoilState(tokenAtom);

    const [drivers, setDrivers] = useState([]);

    const [filter, setFilter] = useState({});
    const [selDriver, setSelDriver] = useState({});

    const [center, setCenter] = useState({
        lat: -4.41890784855677,
        lng: 15.311916786056923
    });

    const [sdate, setSDate] = useState(Moment().startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isDate, setIsDate] = useState(false);
    const [selection, setSelection] = useState(false);

    const alert = useAlert();
    const navigate = useNavigate();
    const driverService = new ServiceDriver();

    const changeTab = (val) => {
        setTab(val);
        setQuery('');
        setSDate(Moment().startOf('year').toDate());
        setEDate(Moment().toDate());
        switch (val) {
            case "all":
                setFilter({});
                break;
            case "active":
                setFilter({ 'status': 'active' });
                break;
            case "banned":
                setFilter({ 'status': 'banned' });
                break;
            case "expired":
                setFilter({ 'status': 'expired' });
                break;
            case "flaged":
                setFilter({ 'status': 'flaged' });
                break;
            default:
                break;
        }
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const getExportItems = () => {
        let items = [];
        drivers.forEach((item) => {
            items.push({
                fname: item.personalInfo.fname,
                mname: item.personalInfo.mname,
                lname: item.personalInfo.lname,
                email: item.personalInfo.email,
                phone: item.personalInfo.phone,
                dob: item.personalInfo.dob,
                address: item.personalInfo.address,
                status: item.status,
                createdAt: item.createdAt,
            });
        });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "drivers");
        XLSX.writeFile(workbook, "drivers.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['fname,mname,lname,email,phone,dob,address,status,createdAt'];
        let usersCsv = drivers.reduce((acc, user) => {
            const { status, createdAt } = user
            acc.push([user.personalInfo.fname, user.personalInfo.mname, user.personalInfo.lname, user.personalInfo.email, user.personalInfo.phone, user.personalInfo.dob, user.personalInfo.address, status, createdAt].join(','))
            return acc
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'drivers.csv',
            fileType: 'text/csv',
        });
    }

    const onDateChange = (start, end) => {
        setIsDate(true);
        setSDate(start);
        setEDate(end);
    }

    const updateStatus = (id, status) => {
        let body = {
            uid: id,
            type: 'only',
            data: {
                status: status
            }
        }
        driverService.updateDriver(body, token).then((res) => {
            if (res['status']) {
                loadData();
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        });
    }

    const deleteDriver = (id) => {
        let body = {
            uid: id,
        }
        driverService.deleteDriverById(body, token).then((res) => {
            if (res['status']) {
                loadData();
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        });
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await driverService.getAllDrivers(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setDrivers(res.data.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await driverService.getAllDrivers(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setDrivers(res.data.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    const getmStatus = (type) => {
        switch (type) {
            case "1":
                return "Single";
            case "2":
                return "Married";
            case "3":
                return "Widowed";
            case "4":
                return "Divorced";
            default:
                return "NA";
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, sdate, edate, filter]);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                selection && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] absolute right-0 h-screen p-[20px]'>
                        <Scrollbars>
                            <div className='px-4'>
                                <Wrapper apiKey={"AIzaSyA7cVpntBjp50n9mYQHHSN8tbVBDWMXzj4"}>
                                    <GoogleMap mapContainerStyle={{ width: '608px', height: '400px' }} center={center} zoom={15.15}>
                                        <Marker position={center} />
                                    </GoogleMap>
                                </Wrapper>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900'>Driver Details</h1>
                                    <span onClick={() => {
                                        setSelection(false);
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <hr className='mt-2' />
                                <div className='w-full mx-4'>
                                    <div className="w-full mt-4">
                                        <div className="intro-y w-full shadow-lg">
                                            <div className='h-28 bg-sky-600 rounded-t-lg relative'>
                                                <div className='bg-sky-600 w-24 h-24 rounded-full flex items-center justify-center border-4 border-white absolute' style={{ bottom: '-3rem', left: '1rem' }}>
                                                    <img src={selDriver.profilePic.fileurl} className="w-full h-full object-cover rounded-full" />
                                                </div>
                                            </div>
                                            <div className='pb-4 px-4 mb-4 rounded-b-lg'>
                                                <div className='text-2xl font-bold ml-28'>{selDriver.personalInfo.fname} {selDriver.personalInfo.mname} {selDriver.personalInfo.lname}</div>
                                                <div className='text-sm text-gray-600 ml-28'>Member since : {moment(selDriver.createdAt).format('DD MMM, yyyy')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='my-4' />
                                    <div className='w-full md:col-span-4 shadow-lg bg-white p-4 rounded-md' style={{ height: 'fit-content' }}>
                                        <h1 className='text-black font-medium text-xl'>Profile Information</h1>
                                        <hr className='my-1' />
                                        <div className='flex items-center mt-2'>
                                            <img className="w-20 md:w-36 h-20 md:h-36 rounded border border-slate-950 mr-3 object-cover" src={selDriver.frontPic.fileurl} />
                                            <img className="w-20 md:w-36 h-20 md:h-36 rounded border border-slate-950 mx-3 object-cover" src={selDriver.smilePic.fileurl} />
                                            <img className="w-20 md:w-36 h-20 md:h-36 rounded border border-slate-950 mx-3 object-cover" src={selDriver.profilePic.fileurl} />
                                        </div>
                                        <div className="text-black text-lg font-medium font-['Rubik'] mt-2">{selDriver.personalInfo.fname} {selDriver.personalInfo.mname} {selDriver.personalInfo.lname}</div>
                                        <div className="text-slate-950 text-sm font-normal font-['Rubik'] mt-2">Email ID:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{selDriver.personalInfo.email === "" ? "NA" : selDriver.personalInfo.email}</span></div>
                                        <div className="text-slate-950 text-sm font-normal font-['Rubik']">DOB:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{selDriver.personalInfo.dob === "" ? "NA" : Moment(selDriver.personalInfo.dob, 'yyyy-MM-DD').format('DD MMM yyyy')}</span></div>
                                        <div className="text-slate-950 text-sm font-normal font-['Rubik']">Phone primary:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{selDriver.personalInfo.phone === "" ? "NA" : "+243 " + selDriver.personalInfo.phone}</span></div>
                                        <div className="text-slate-950 text-sm font-normal font-['Rubik']">Phone secondary:&nbsp;<span className="text-black text-xs font-medium font-['Rubik']">{selDriver.personalInfo.phoneb === "" ? "NA" : "+243 " + selDriver.personalInfo.phoneb}</span></div>
                                        <div className="text-slate-950 text-sm font-normal font-['Rubik']">Marital Status:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{getmStatus(selDriver.personalInfo.mStatus)}</span></div>
                                        <div className="text-slate-950 text-sm font-normal font-['Rubik']">Number of children:&nbsp;<span className="text-black text-sm font-medium font-['Rubik']">{selDriver.personalInfo.noc === "" ? "NA" : selDriver.personalInfo.noc}</span></div>
                                        <div className="text-slate-950 text-sm font-normal font-['Rubik']">Physical Address 1</div>
                                        <div className="text-slate-400 text-sm font-normal font-['Rubik'] pr-6">{selDriver.personalInfo.address === "" ? "NA" : selDriver.personalInfo.address}</div>
                                        <div className="text-slate-950 text-sm font-normal font-['Rubik']">Physical Address 2</div>
                                        <div className="text-slate-400 text-sm font-normal font-['Rubik'] pr-6">{selDriver.personalInfo.addressb === "" ? "NA" : selDriver.personalInfo.addressb}</div>
                                    </div>
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            }
            <Scrollbars>
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10">
                            <h2 class="text-2xl font-medium truncate ml-2">
                                <motion.i whileHover={{ scale: 1.2 }} className="las la-bars cursor-pointer" onClick={() => { props.onToggle() }}></motion.i> Taxi Driver Master
                            </h2>
                            <div className='flex'>
                                <TopBar />
                            </div>
                        </div>
                        <div class="w-full mt-2">
                            <div className="p-2 mt-2">
                                <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'all' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('all') }}>All</button></li>
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'active' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('active') }}>Active</button></li>
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'expired' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('expired') }}>Expired</button></li>
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'flaged' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('flaged') }}>Flaged</button></li>
                                    <li className="mx-2"><button className={`py-2 px-2 ${tab === 'banned' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('banned') }}>Banned</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 mt-4 px-2">
                    <div className="w-full shadow-md rounded-md border">
                        <table className="min-w-full divide-y divide-gray-200 px-4">
                            <thead className="bg-gray-50">
                                <tr className=''>
                                    <th scope="col" className="border-b border-gray-200" colSpan={7}>
                                        <div className='w-full grid grid-cols-12 gap-2'>
                                            <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                <div className='col-span-4'>
                                                    <RangeDatePicker
                                                        startDate={sdate}
                                                        endDate={edate}
                                                        minDate={new Date(2022, 8, 1)}
                                                        maxDate={Moment().toDate()}
                                                        disabled={false}
                                                        onCloseCalendar={(e) => {
                                                            loadData();
                                                        }}
                                                        onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                        className=""
                                                        startWeekDay="monday"
                                                    />
                                                </div>
                                                <div className='w-full flex col-span-8 items-center'>
                                                    <div className='w-[80px]'>
                                                        <p>Search :</p>
                                                    </div>
                                                    <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by name, email and phone." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                </div>
                                            </form>
                                            <div className='col-span-1 flex items-center'>
                                                <Menu>
                                                    <Menu.Button>
                                                        <button className="w-full py-1 px-2 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                    </Menu.Button>
                                                    <Transition
                                                        enter="transition duration-100 ease-out"
                                                        enterFrom="transform scale-95 opacity-0"
                                                        enterTo="transform scale-100 opacity-100"
                                                        leave="transition duration-75 ease-out"
                                                        leaveFrom="transform scale-100 opacity-100"
                                                        leaveTo="transform scale-95 opacity-0">
                                                        <Menu.Items>
                                                            <div
                                                                className='bg-white shadow-md rounded-md absolute' style={{ top: '32px', left: '-176px' }}>
                                                                <div>
                                                                    <ul className='flex flex-col items-start p-2'>
                                                                        <Menu.Item>
                                                                            <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                exportToExcel();
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                                </div>
                                                                            </li>
                                                                        </Menu.Item>
                                                                        <Menu.Item>
                                                                            <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                exportToCsv();
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                                </div>
                                                                            </li>
                                                                        </Menu.Item>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Name
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Email ID
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Phone Number
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Membership Expiry
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Created At
                                    </th>
                                    <th scope="col" className="px-2 py-3"></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    drivers.length !== 0 && drivers.map((driver) => {
                                        return <tr className="hover:bg-gray-100">
                                            <td className="px-6 py-4 whitespace-nowrap" onClick={() => {
                                                setSelDriver(driver);
                                                setSelection(true);
                                            }}>
                                                <div className="text-sm font-bold text-blue-600 uppercase cursor-pointer">
                                                    {driver.personalInfo.fname} {driver.personalInfo.mname} {driver.personalInfo.lname}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900">
                                                    {driver.personalInfo.email === "" ? "NA" : driver.personalInfo.email}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900">
                                                    +243 {driver.personalInfo.phone}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-blue-600">
                                                    {Moment(driver.endDate).format('DD, MMM yyyy')}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${driver.status == 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                                    {driver.status.toUpperCase()}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-blue-600">
                                                    {Moment(driver.createdAt).format('DD, MMM yyyy')}
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    <Menu>
                                                        <Menu.Button>
                                                            <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                        </Menu.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0">
                                                            <Menu.Items>
                                                                <div
                                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-142px' }}>
                                                                    <div>
                                                                        <ul className='flex flex-col items-start p-2'>
                                                                            {
                                                                                driver.status == 'active' && <Menu.Item>
                                                                                    <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                        updateStatus(driver._id, 'banned');
                                                                                    }}>
                                                                                        <div className='flex items-center text-base'>
                                                                                            <i className="las la-ban text-lg"></i>
                                                                                            <p className='text-sm font-serif ml-2'>Block</p>
                                                                                        </div>
                                                                                    </li>
                                                                                </Menu.Item>
                                                                            }
                                                                            {
                                                                                driver.status == 'active' && <Menu.Item>
                                                                                    <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                        updateStatus(driver._id, 'flaged');
                                                                                    }}>
                                                                                        <div className='flex items-center text-base'>
                                                                                            <i className="las la-flag text-lg"></i>
                                                                                            <p className='text-sm font-serif ml-2'>Flag</p>
                                                                                        </div>
                                                                                    </li>
                                                                                </Menu.Item>
                                                                            }
                                                                            {
                                                                                driver.status == 'flaged' && <Menu.Item>
                                                                                    <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                        updateStatus(driver._id, 'active');
                                                                                    }}>
                                                                                        <div className='flex items-center text-base'>
                                                                                            <i className="las la-chevron-circle-up text-lg"></i>
                                                                                            <p className='text-sm font-serif ml-2'>Un-Flag</p>
                                                                                        </div>
                                                                                    </li>
                                                                                </Menu.Item>
                                                                            }
                                                                            {
                                                                                driver.status == 'banned' && <Menu.Item>
                                                                                    <li className='w-32 cursor-pointer hover:bg-green-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                        updateStatus(driver._id, 'active');
                                                                                    }}>
                                                                                        <div className='flex items-center text-base'>
                                                                                            <i className="las la-chevron-circle-up text-lg"></i>
                                                                                            <p className='text-sm font-serif ml-2'>Active</p>
                                                                                        </div>
                                                                                    </li>
                                                                                </Menu.Item>
                                                                            }
                                                                            {
                                                                                driver.status == 'banned' && <Menu.Item>
                                                                                    <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                        deleteDriver(driver._id);
                                                                                    }}>
                                                                                        <div className='flex items-center text-base'>
                                                                                            <i className="las la-trash text-lg"></i>
                                                                                            <p className='text-sm font-serif ml-2'>Delete</p>
                                                                                        </div>
                                                                                    </li>
                                                                                </Menu.Item>
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                }
                                            </td>
                                        </tr>
                                    })
                                }
                                {
                                    drivers.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                        <td className="px-6 py-4 whitespace-nowrap" colSpan="7">
                                            <div className="text-sm text-blue-600 text-center">
                                                No data found.
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <div className='flex py-2 px-4 items-center bg-blue-50'>
                            <p className='text-sm font-serif'>Show rows per page</p>
                            <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                            </select>
                            <div className='flex-grow'></div>
                            <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                            <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                            <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div >
    )
}
