import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Scrollbars } from 'react-custom-scrollbars';
import { useAlert } from 'react-alert';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import ComponentCustomerInfo from './component/ComponentCustomerInfo';

import ServiceCustomer from '../../services/serviceCustomer';

export default function PanelCustomerInfo() {

    const { id } = useParams();

    const [token] = useRecoilState(tokenAtom);

    const [customers, setCustomers] = useState([]);

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [query, setQuery] = useState('');

    const [selID, setSelID] = useState(id);

    const alert = useAlert();
    const navigate = useNavigate();
    const customerService = new ServiceCustomer();

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": {},
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            var res = await customerService.getAllCustomers(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setCustomers(res.data.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": {},
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            var res = await customerService.getAllCustomers(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setCustomers(res.data.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    const gotoCustomer = () => {
        navigate(`/home/customer`);
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query]);

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <div className="w-[420px] h-screen bg-slate-50 border-r-2">
                <Scrollbars>
                    <div className='w-full'>
                        <div className='flex items-center justify-between px-2 pt-4'>
                            <div className='h-[28px] w-[28px] rounded-md flex items-center justify-center cursor-pointer hover:bg-gray-200' onClick={() => {
                                gotoCustomer();
                            }}>
                                <i className="las la-arrow-left text-lg"></i>
                            </div>
                            <h1 className="text-xl lg:text-2xl font-medium ml-2">Customers</h1>
                        </div>
                        <div className='h-[2px] bg-gray-400 mt-2'></div>
                        <ul className="w-full flex flex-col items-start">
                            {
                                customers.map((user) => {
                                    return <div className={`w-full cursor-pointer ${selID === user.cid ? "bg-blue-100 hover:bg-blue-50" : "bg-white hover:bg-gray-100"}`} key={user._id} onClick={() => {
                                        setSelID(user.cid);
                                    }}>
                                        <div className='flex items-center px-1 py-2'>
                                            <div className='w-[48px] h-[48px] mr-2'>
                                                {
                                                    (user['profilePic'] && user['profilePic']['fileurl'] !== "") ? <img src={user['profilePic']['fileurl']} alt='Profile Pic' className='w-full h-full rounded-full border-2 border-blue-600' /> : <div className='w-full h-full rounded-full bg-gray-100 flex items-center justify-center'>
                                                        <h1 className='uppercase font-medium'>{user['fname'][0]}{user['lname'][0]}</h1>
                                                    </div>
                                                }
                                            </div>
                                            <div>
                                                <h3 className='text-base font-medium'>{user['fname']} {user['mname']} {user['lname']}</h3>
                                                <p className='text-gray-600 text-sm'>+243 {user['phone']}</p>
                                            </div>
                                        </div>
                                        <div className='h-[1px] bg-gray-400 mt-1'></div>
                                    </div>
                                })
                            }
                        </ul>
                        <div className='h-[2px] bg-gray-400'></div>
                        <div className='flex py-2 px-1 items-center bg-blue-50'>
                            <select className='text-sm bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                            </select>
                            <div className='flex-grow'></div>
                            <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                            <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                            <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                        </div>
                    </div>
                </Scrollbars>
            </div>
            <div className='w-full h-screen bg-white'>
                <Scrollbars>
                    <ComponentCustomerInfo cid={selID} />
                </Scrollbars>
            </div>
        </div>
    )
}
