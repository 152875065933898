import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Lottie from 'lottie-react';

import NavigationBar from '../core/widgets/ui/NavigationBar';

import LayoutCMS from '../layouts/LayoutCMS';
import LayoutDriver from '../layouts/LayoutDriver';
import LayoutCustomer from '../layouts/LayoutCustomer';
import LayoutStaff from '../layouts/LayoutStaff';
import LayoutSetting from '../layouts/LayoutSetting';
import LayoutReports from '../layouts/LayoutReports';

import animSad from '../assets/anim/anim-sad.json';

export default function PageHome() {

    const { page } = useParams();

    const { t } = useTranslation();

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <NavigationBar className="z-50" />
            {
                (() => {
                    switch (page) {
                        case 'overview':
                        case 'membership':
                            return (<LayoutCMS />);
                        case 'applications':
                        case 'applicationAdd':
                        case 'applicationVerify':
                        case 'driverVerify':
                        case 'vehicleVerify':
                        case 'CR1Verify':
                        case 'CR2Verify':
                        case 'CR3Verify':
                        case 'CR4Verify':
                        case 'driver':
                        case 'vehicle':
                        case 'subscription':
                            return (<LayoutDriver />);
                        case 'customer':
                        case 'customerInfo':
                            return (<LayoutCustomer />);
                        case 'report':
                            return (<LayoutReports />);
                        case 'staff':
                        case 'staffAdd':
                            return (<LayoutStaff />);
                        case 'setting':
                            return (<LayoutSetting />);
                        default:
                            return (
                                <div className="w-full h-full text-5xl flex items-center justify-center" style={{ height: "80vh" }}>
                                    <div className="container">
                                        <Lottie animationData={animSad} className="intro-y w-24 mx-auto mb-20" loop={true} />
                                        <h2 className="intro-y text-red-600 text-center lg:text-big text-9xl font-serif font-bold">404</h2>
                                        <p className=" intro-y text-gray-900 lg:text-lg text-base text-center font-serif font-normal w-4/5 mx-auto">{t('errM1')}<br />{t('errM2')}</p>
                                    </div>
                                </div>
                            );
                    }
                })()
            }
        </div>
    )
}