import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import Lottie from 'lottie-react';

import ServiceApplication from '../../../../services/serviceApplication';
import ServiceUtility from '../../../../services/serviceUtility';

import animBtnLoading from '../../../../assets/anim/anim-btnLoading.json';

const allowedExtensions = ["png", "jpeg"];

export default function ComponentStepC(props) {

    const [token] = useRecoilState(tokenAtom);

    const [files, setFiles] = useState([null, null]);
    const [previews, setPreviews] = useState([null, null]);

    const [submit, setSubmit] = useState(false);

    const alert = useAlert();
    const fileService = new ServiceUtility();
    const appService = new ServiceApplication();

    const formVSchema = Yup.object().shape({
        emAName: Yup.string().required('This information is required'),
        emAPhone: Yup.string().matches(/^[0-9]{10}$/, { message: 'Please enter valid phone number' }).required('This information is required'),
        emAAddress: Yup.string().required('This information is required'),
        emBPhone: Yup.string().matches(/^[0-9]{10}$/, { message: 'Please enter valid phone number' }),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            emAName: '',
            emAPhone: '',
            emAPhoneb: '',
            emAAddress: '',
            emBName: '',
            emBPhone: '',
            emBPhoneb: '',
            emBAddress: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            if (files.every(file => file === null)) {
                let body = {};
                if (props.data.data.emrgencyInfo) {
                    body = {
                        "fid": props.data._id,
                        "opration": "updateStepC",
                        "data": {
                            emAName: values.emAName,
                            emAPhone: values.emAPhone,
                            emAPhoneb: values.emAPhoneb,
                            emAAddress: values.emAAddress,
                            emAPic: props.data.data.emrgencyInfo.emAPic,
                            emBName: values.emBName,
                            emBPhone: values.emBPhone,
                            emBPhoneb: values.emBPhoneb,
                            emBAddress: values.emBAddress,
                            emBPic: props.data.data.emrgencyInfo.emBPic,
                        }
                    }
                } else {
                    body = {
                        "fid": props.data._id,
                        "opration": "updateStepC",
                        "data": {
                            emAName: values.emAName,
                            emAPhone: values.emAPhone,
                            emAPhoneb: values.emAPhoneb,
                            emAAddress: values.emAAddress,
                            emAPic: {},
                            emBName: values.emBName,
                            emBPhone: values.emBPhone,
                            emBPhoneb: values.emBPhoneb,
                            emBAddress: values.emBAddress,
                            emBPic: {},
                        }
                    }
                }
                appService.updateFormA(body, token).then((res) => {
                    setSubmit(false);
                    if (res.status) {
                        alert.show("Application updated!", { type: 'success' });
                        props.gotoNext(4, res.data);
                    } else {
                        alert.show("Server error, please try again!", { type: 'error' });
                    }
                });
            } else {
                let promis = [];
                if (files[0] !== null) {
                    var formDataA = new FormData();
                    if (getIsDoc('A')) {
                        formDataA.append("path", props.data.data.emrgencyInfo.emAPic.fileurl);
                        formDataA.append("doc", files[0]);
                        promis.push(fileService.replaceFile(formDataA, token));
                    } else {
                        formDataA.append("path", 'emContacts');
                        formDataA.append("doc", files[0]);
                        promis.push(fileService.uploadFile(formDataA, token));
                    }
                } else {
                    promis.push(new Promise(
                        resolve => setTimeout(resolve, 400)
                    ));
                }

                if (files[1] !== null) {
                    var formDataA = new FormData();
                    if (getIsDoc('B')) {
                        formDataA.append("path", props.data.data.emrgencyInfo.emBPic.fileurl);
                        formDataA.append("doc", files[1]);
                        promis.push(fileService.replaceFile(formDataA, token));
                    } else {
                        formDataA.append("path", 'emContacts');
                        formDataA.append("doc", files[1]);
                        promis.push(fileService.uploadFile(formDataA, token));
                    }
                } else {
                    promis.push(new Promise(
                        resolve => setTimeout(resolve, 400)
                    ));
                }

                Promise.all(promis).then((ress) => {
                    let body = {
                        "fid": props.data._id,
                        "opration": "updateStepC",
                        "data": {
                            emAName: values.emAName,
                            emAPhone: values.emAPhone,
                            emAPhoneb: values.emAPhoneb,
                            emAAddress: values.emAAddress,
                            emAPic: {},
                            emBName: values.emBName,
                            emBPhone: values.emBPhone,
                            emBPhoneb: values.emBPhoneb,
                            emBAddress: values.emBAddress,
                            emBPic: {},
                        }
                    }

                    if (files[0] !== null) {
                        if (ress[0].status) {
                            body["data"]["emAPic"] = ress[0].data;
                        } else {
                            alert.show("File upload error, please try again!", { type: 'error' });
                        }
                    } else if (getIsDoc('A')) {
                        body["data"]["emAPic"] = props.data.data.emrgencyInfo.emAPic;
                    }

                    if (files[1] !== null) {
                        if (ress[1].status) {
                            body["data"]["emBPic"] = ress[1].data;
                        } else {
                            alert.show("File upload error, please try again!", { type: 'error' });
                        }
                    } else if (getIsDoc('B')) {
                        body["data"]["emBPic"] = props.data.data.emrgencyInfo.emBPic;
                    }

                    appService.updateFormA(body, token).then((res) => {
                        setSubmit(false);
                        if (res.status) {
                            alert.show("Application updated!", { type: 'success' });
                            props.gotoNext(4, res.data);
                        } else {
                            alert.show("Server error, please try again!", { type: 'error' });
                        }
                    });
                });
            }
        },
    });

    const handleFileChange = (e, index) => {
        var { value, name } = e.target;
        let isError = false;

        const inputFile = e.target.files[0];
        const fileExtension = inputFile?.type.split("/")[1];

        if (!allowedExtensions.includes(fileExtension)) {
            alert.show("Only .png file format is allowed, please try again!", { type: 'error' });
            isError = true;
        }

        if (isError) {
            return;
        }
        const newFiles = [...files];
        newFiles[index] = inputFile;
        setFiles(newFiles);

        const newPreviews = [...previews];
        const reader = new FileReader();
        reader.onload = () => {
            newPreviews[index] = reader.result;
            setPreviews(newPreviews);
        };
        reader.readAsDataURL(inputFile);
    };

    const getIsDoc = (cheack) => {
        if (props.data.data.emrgencyInfo) {
            switch (cheack) {
                case 'A':
                    return props.data.data.emrgencyInfo.emAPic;
                case 'B':
                    return props.data.data.emrgencyInfo.emBPic;
            }
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (props.data.data.emrgencyInfo) {
            setValues({ ...values, ...props.data.data.emrgencyInfo });
        }
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <div className="mt-4 grid grid-cols-12 gap-6">
                <div className="intro-y mt-5 md:mt-0 col-span-12">
                    <div className="shadow">
                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                            <div className="grid grid-cols-6 gap-6">

                                <div className="col-span-6">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Emergency Information</h3>
                                </div>

                                <div className="col-span-6">
                                    <h4 className="text-sm font-medium text-gray-600">Emergency Information Person 1</h4>
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Name of emergency contact<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="emAName" value={values.emAName} onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Full name" />
                                    {(errors.emAName && touched.emAName) && <p className='text-xs text-red-400 mt-1'>{errors.emAName}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Telephone number 1<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="emAPhone" value={values.emAPhone} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 012345689" maxLength="10" minLength="10" />
                                    {(errors.emAPhone && touched.emAPhone) && <p className='text-xs text-red-400 mt-1'>{errors.emAPhone}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Telephone number 2 <span className="text-gray-400">(Optional)</span></label>
                                    <input type="text" id="emAPhoneb" value={values.emAPhoneb} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 012345689" maxLength="10" minLength="10" />
                                    {(errors.emAPhoneb && touched.emAPhoneb) && <p className='text-xs text-red-400 mt-1'>{errors.emAPhoneb}</p>}
                                </div>

                                <div className="col-span-6">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Physical address 1<sup className="text-red-600">*</sup></label>
                                    <textarea id="emAAddress" value={values.emAAddress} onChange={handleChange} autoComplete="street-address" className="mt-1 p-2 block w-full h-20 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Street address"></textarea>
                                    {(errors.emAAddress && touched.emAAddress) && <p className='text-xs text-red-400 mt-1'>{errors.emAAddress}</p>}
                                </div>

                                <div className="col-span-6 md:col-span-2">
                                    <div className="py-2 bg-white">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Photo document of emergency contact <span className='text-xs text-gray-400'>(optional)</span>
                                            </label>
                                            {
                                                (files[0] === null && !getIsDoc('A')) && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                    <div className="space-y-1 text-center">
                                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <div className="flex text-sm text-gray-600">
                                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                <span>Upload a file</span>
                                                                <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 0) }} />
                                                            </label>
                                                            <p className="pl-1">or drag and drop</p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">
                                                            PNG, JPG, PDF up to 1MB
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                files[0] !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                    <div className="w-full flex items-center justify-center py-4">
                                                        <img src={previews[0]} className='w-[400px] h-[400px]' />
                                                    </div>
                                                    <hr />
                                                    <div className="flex justify-between mt-2">
                                                        <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                            <span>View</span>
                                                        </label>
                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                            <span>Replace</span>
                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 0) }} />
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (files[0] === null && getIsDoc('A')) && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                    <div className="w-full flex items-center justify-center py-4">
                                                        <img src={props.data.data.emrgencyInfo.emAPic.fileurl} className='w-[400px] h-[400px]' />
                                                    </div>
                                                    <hr />
                                                    <div className="flex justify-between mt-2">
                                                        <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                            <span>View</span>
                                                        </label>
                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                            <span>Replace</span>
                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 0) }} />
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-6">
                                    <h4 className="text-sm font-medium text-gray-600">Emergency Information Person 2 <span className='text-xs text-gray-400'>(optional)</span></h4>
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Name of emergency contact <span className="text-gray-400">(Optional)</span></label>
                                    <input type="text" id="emBName" value={values.emBName} onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Full name" />
                                    {(errors.emBName && touched.emBName) && <p className='text-xs text-red-400 mt-1'>{errors.emBName}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Telephone number 1 <span className="text-gray-400">(Optional)</span></label>
                                    <input type="text" id="emBPhone" value={values.emBPhone} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 012345689" maxLength="10" minLength="10" />
                                    {(errors.emBPhone && touched.emBPhone) && <p className='text-xs text-red-400 mt-1'>{errors.emBPhone}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Telephone number 2 <span className="text-gray-400">(Optional)</span></label>
                                    <input type="text" id="emBPhoneb" value={values.emBPhoneb} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 012345689" maxLength="10" minLength="10" />
                                    {(errors.emBPhoneb && touched.emBPhoneb) && <p className='text-xs text-red-400 mt-1'>{errors.emBPhoneb}</p>}
                                </div>

                                <div className="col-span-6">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Physical address 1 <span className="text-gray-400">(Optional)</span></label>
                                    <textarea id="emBAddress" value={values.emBAddress} onChange={handleChange} autoComplete="street-address" className="mt-1 p-2 block w-full h-20 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Street address"></textarea>
                                    {(errors.emBAddress && touched.emBAddress) && <p className='text-xs text-red-400 mt-1'>{errors.emBAddress}</p>}
                                </div>

                                <div className="col-span-6 md:col-span-2">
                                    <div className="py-2 bg-white">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Photo document of emergency contact <span className='text-xs text-gray-400'>(optional)</span>
                                            </label>
                                            {
                                                (files[1] === null && !getIsDoc('B')) && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                    <div className="space-y-1 text-center">
                                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <div className="flex text-sm text-gray-600">
                                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                <span>Upload a file</span>
                                                                <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 1) }} />
                                                            </label>
                                                            <p className="pl-1">or drag and drop</p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">
                                                            PNG, JPG, PDF up to 1MB
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                files[1] !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                    <div className="w-full flex items-center justify-center py-4">
                                                        <img src={previews[1]} className='w-[400px] h-[400px]' />
                                                    </div>
                                                    <hr />
                                                    <div className="flex justify-between mt-2">
                                                        <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                            <span>View</span>
                                                        </label>
                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                            <span>Replace</span>
                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 1) }} />
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (files[1] === null && getIsDoc('B')) && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                    <div className="w-full flex items-center justify-center py-4">
                                                        <img src={props.data.data.emrgencyInfo.emBPic.fileurl} className='w-[400px] h-[400px]' />
                                                    </div>
                                                    <hr />
                                                    <div className="flex justify-between mt-2">
                                                        <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                            <span>View</span>
                                                        </label>
                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                            <span>Replace</span>
                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e, 1) }} />
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-y hidden sm:block">
                <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                </div>
            </div>
            <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700">
                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                    {!submit && "SAVE & NEXT"}
                </button>
            </div>
            <div className='h-[60px] md:hidden'></div>
        </form>
    )
}
