import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { motion } from "framer-motion";
import { Scrollbars } from 'react-custom-scrollbars';
import Lottie from 'lottie-react';
import { useAlert } from 'react-alert';
import { Menu, Transition, Dialog } from '@headlessui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import animPage from '../../assets/anim/anim-pageload.json';
import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import TopBar from '../../core/widgets/ui/TopBar';

import ServicePlan from '../../services/servicePlan';

export default function PanelMembership(prpos) {

    const [token] = useRecoilState(tokenAtom);

    const [members, setMembers] = useState([]);

    const [pid, setPid] = useState('');
    const [lang, setLang] = useState('en');

    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [submit, setSubmit] = useState(false);

    const alert = useAlert();
    const planService = new ServicePlan();

    const formVSchema = Yup.object().shape({
        title: Yup.string().required('This information is required'),
        disc: Yup.string().required('This information is required'),
        rate: Yup.number().required('This information is required'),
        tenuare: Yup.number().required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            title: '',
            disc: '',
            rate: 0,
            tenuare: 0
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                "pid": pid,
                "data": values,
            }
            planService.updatePlan(body, token).then((res) => {
                if (res.status) {
                    setSubmit(false);
                    alert.show("Plan updated!", { type: 'success' });
                    loadData(lang);
                } else {
                    setSubmit(false);
                    alert.show("Server error, please try again!", { type: 'error' });
                }
                setIsOpen(false);
                handleReset();
            });
        }
    });

    const openUpdate = (member) => {
        setPid(member._id);
        setValues({
            title: member.title,
            disc: member.disc,
            rate: member.rate,
            tenuare: member.tenuare
        });
        setIsOpen(true);
    }

    const loadData = (ln) => {
        (async function () {
            var body = {
                'lang': ln
            };
            var res = await planService.getPlans(body, token);
            if (res['status']) {
                setMembers(res.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData(lang);
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Update Plan &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpen(false);
                            handleReset();
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handleSubmit} id="plan-form">
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Title<sup className="text-red-600">*</sup></label>
                            <input type="text" id="title" name="title" value={values.title} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. title" onChange={handleChange} />
                            {(errors.title && touched.title) && <p className='text-xs text-red-400 mt-1'>{errors.title}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Description<sup className="text-red-600">*</sup></label>
                            <textarea type="text" id="disc" name="disc" value={values.disc} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full h-28 p-2.5" placeholder="i.e. disc" onChange={handleChange} />
                            {(errors.disc && touched.disc) && <p className='text-xs text-red-400 mt-1'>{errors.disc}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Rate in ($)<sup className="text-red-600">*</sup></label>
                            <input type="text" id="rate" name="rate" value={values.rate} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. rate" onChange={handleChange} />
                            {(errors.rate && touched.rate) && <p className='text-xs text-red-400 mt-1'>{errors.rate}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Tenuare in (Days)<sup className="text-red-600">*</sup></label>
                            <input type="text" id="tenuare" name="tenuare" value={values.tenuare} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. tenuare" onChange={handleChange} />
                            {(errors.tenuare && touched.tenuare) && <p className='text-xs text-red-400 mt-1'>{errors.tenuare}</p>}
                        </div>
                        <div className='flex items-end'>
                            <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && "Update"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <div className="intro-y flex items-center justify-between h-10">
                <h2 className="text-2xl font-medium truncate ml-2">
                    <motion.i whileHover={{ scale: 1.2 }} className="las la-bars cursor-pointer" onClick={() => { prpos.onToggle(); }}></motion.i> Membership Plans
                </h2>
                <div className='flex z-50'>
                    <Menu>
                        <Menu.Button>
                            <button className="py-1 px-3 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-language mr-2"></i> Language (<span className='text-xs'>{lang}</span>)</button>
                        </Menu.Button>
                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0">
                            <Menu.Items>
                                <div
                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '52px', left: '-190px' }}>
                                    <div>
                                        <ul className='flex flex-col items-start p-2'>
                                            <Menu.Item>
                                                <li className={`w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md ${lang === 'en' ? 'flex justify-between items-center' : ''}`} onClick={() => {
                                                    setLang('en');
                                                    loadData('en');
                                                }}>
                                                    <div className='flex items-center text-base'>
                                                        <p className={`text-sm font-serif ml-2 ${lang === 'En' ? 'font-medium text-green-600' : ''}`}>English</p>
                                                    </div>
                                                    {lang === 'en' && <i className={`las la-check-circle ${lang === 'en' ? 'font-medium text-green-600' : ''}`}></i>}
                                                </li>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <li className={`w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md ${lang === 'fr' ? 'flex justify-between items-center' : ''}`} onClick={() => {
                                                    setLang('fr');
                                                    loadData('fr');
                                                }}>
                                                    <div className='flex items-center text-base'>
                                                        <p className={`text-sm font-serif ml-2 ${lang === 'Fr' ? 'font-medium text-green-600' : ''}`}>French</p>
                                                    </div>
                                                    {lang === 'fr' && <i className={`las la-check-circle ${lang === 'fr' ? 'font-medium text-green-600' : ''}`}></i>}
                                                </li>
                                            </Menu.Item>
                                        </ul>
                                    </div>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <TopBar />
                </div>
            </div>
            <Scrollbars>
                <hr className='mt-2' />
                <div className='m-2'>
                    {
                        !loading && <ul>
                            {
                                members.map((member) => {
                                    return <li className="w-full bg-white rounded-lg shadow-md cursor-pointer border-t-[4px] border-t-sky-400 p-4 font-sans my-4" key={member._id}>
                                        <div className='flex items-center justify-between'>
                                            <p className="text-black text-xl font-semibold">{member.title}</p>
                                            <p className="text-blue-600 text-base font-medium" onClick={() => {
                                                openUpdate(member);
                                            }}><i className="las la-pen"></i>Edit</p>
                                        </div>
                                        <hr className='my-2' />
                                        <div className="text-slate-400 text-sm">{member.disc}</div>
                                        <div className='mt-2 flex items-center'>
                                            <div className="text-blue-600 text-lg font-semibold">$ {member.rate} /</div>
                                            <div className="text-slate-400 text-lg ml-1">{member.tenuare} Days</div>
                                        </div>
                                    </li>
                                })
                            }
                            {
                                members.length === 0 && <li className="w-full bg-white rounded-lg shadow-md p-12 border border-dashed flex items-center justify-center">
                                    <div className="text-blue-600 text-sm font-semibold">No data found.</div>
                                </li>
                            }
                        </ul>
                    }
                </div>
            </Scrollbars>
        </div>
    )
}
