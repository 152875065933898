// import axios from 'axios';
import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceDriver {
    lookupService = new APIServiceLookUp();

    async updateDriver(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}driver/updateDriver`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllDrivers(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}driver/getAllDrivers`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getDriverById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}driver/getDriverById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deleteDriverById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}driver/deleteDriver`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}