import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceLog {
    lookupService = new APIServiceLookUp();

    async getAllLogs(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}log/getAllLogs`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}